import React, { useEffect, useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { draftjsToMd } from "draftjs-md-converter";
import { EditorState, convertToRaw } from "draft-js";

import QuizTableEditor from "../QuizTableEditor";
import MarkdownEditor from "../MarkdownEditor";
import Radio from "../Radio";
import Checkbox from "../Checkbox";
import Button from "../Button";
import UserChatBubble from "../UserChatBubble";
import BulletEditor from "../BulletEditor";
import DragMatch from "../DragMatch";

import "./style.css";
import LoadingAnimation from "../LoadingAnimation";
import HeaderDescriptionEditor from "../HeaderDescriptionEditor";
import SubQuestion from "./SubQuestions";

const BotQuizBubble = ({
  questionData,
  avatarIcon,
  onAnswerInput,
  isLastQuestion,
  onSubmit,
  updateScrollbar,
  questionId,
}) => {
  const [textInputValue, setTextInputValue] = useState(
    EditorState.createEmpty()
  );
  const [groupAnswersArray, setGroupAnswersArray] = useState([]);
  const [options, setOptions] = useState([]);
  const [isAnswered, setIsAnswered] = useState(false);
  const optionsMap = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [tableInputValue, setTableInputValue] = useState([["", ""]]);
  const [bulletArray, setBulletArray] = useState([""]);
  const [enterComplete, setEnterComplete] = useState({
    status: false,
    identifier: "",
    index: "",
  });
  const [matchAnswer, setMatchAnswer] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
      updateScrollbar();
    }, 2000);
  }, []);

  useEffect(() => {
    if (
      questionData &&
      (questionData.type === "radio" || questionData.type === "checkbox")
    ) {
      let temp = {};
      questionData.options.forEach((option) => {
        temp[option.id] = option.text;
      });
      optionsMap.current = temp;
    }
  }, [questionData]);

  useEffect(() => {
    if (enterComplete.status) {
      let element = document.getElementById(
        `input${enterComplete.identifier.toString()}${enterComplete.index.toString()}`
      );
      if (element) {
        element.focus();
      }
    }
  }, [enterComplete]);

  const getBotQuestionHtml = () => (
    <div
      className="quiz-question-frame"
      dangerouslySetInnerHTML={{ __html: questionData.text }}
    />
  );

  const onTextChange = (value) => {
    setTextInputValue(value);
  };

  const onTableChange = (value) => {
    setTableInputValue(value);
  };

  const onMatchAnswerChange = (value) => {
    setMatchAnswer(value);
  };

  const onCheckboxChange = (checked, optionId) => {
    let tempOptions = [...options];
    if (checked) {
      tempOptions.push(optionId);
    } else if (!checked) {
      tempOptions = removeValue(tempOptions, optionId);
    }
    setOptions(tempOptions);
  };

  const convertToMarkdown = (data) => {
    const content = data.getCurrentContent();
    let markup = draftjsToMd(convertToRaw(content));
    let splitMarkup = markup.replace("&nbsp;", " ");

    let transformedMarkup = splitMarkup.trim();
    return transformedMarkup;
  };

  const onSubQuestionAnswerChange = (value, type, questionId) => {
    if (type === "text") {
      let tempAnswers = { ...groupAnswersArray };
      tempAnswers[questionId] = {
        ...groupAnswersArray[questionId],
        question_id: questionId,
        text: value,
      };
      setGroupAnswersArray(tempAnswers);
    } else if (type === "radio" || type === "checkbox") {
      let tempAnswers = { ...groupAnswersArray };
      tempAnswers[questionId] = {
        ...groupAnswersArray[questionId],
        question_id: questionId,
        options: value,
      };
      setGroupAnswersArray(tempAnswers);
    } else if (type === "matching") {
      let tempAnswers = { ...groupAnswersArray };
      let temp = [];
      Object.keys(value).forEach((key) => {
        temp.push({
          term: key,
          definition: value[key] ? value[key].id : "",
        });
      });
      tempAnswers[questionId] = {
        ...groupAnswersArray[questionId],
        question_id: questionId,
        matches: temp,
      };
      setGroupAnswersArray(tempAnswers);
    }
  };

  const onTextAnswerSubmit = () => {
    onAnswerInput(convertToMarkdown(textInputValue), "text", questionId);
    setIsAnswered(true);
  };

  const onTableAnswerSubmit = () => {
    if (
      questionData.answer_key_settings &&
      questionData.answer_key_settings.enable_column_header
    ) {
      tableInputValue.map((row) => row.shift());
    }

    onAnswerInput(JSON.stringify(tableInputValue), "text", questionId);
    setIsAnswered(true);
  };

  const onMatchAnswerSubmit = () => {
    onAnswerInput(matchAnswer, "matching", questionId);
    setIsAnswered(true);
  };

  const onBulletAnswerSubmit = () => {
    if (bulletArray.length === 1 && bulletArray[0] === "") {
      onAnswerInput("", "text", questionId);
    } else {
      let filtered = bulletArray.filter(Boolean);

      onAnswerInput("- " + filtered.join("\n- "), "text", questionId);
    }
    setIsAnswered(true);
  };

  const onRadioAnswerSubmit = (value) => {
    setOptions([value]);
    onAnswerInput([value], "radio", questionId);
    setIsAnswered(true);
  };

  const onCheckboxAnswerSubmit = () => {
    onAnswerInput(options, "checkbox", questionId);
    setIsAnswered(true);
  };

  const removeValue = (list, value) => {
    let newList = [...list];
    for (var i = 0; i < newList.length; i++) {
      if (newList[i] === value) {
        newList.splice(i, 1);
        i--;
      }
    }
    return newList;
  };

  const shouldBulletSubmitDisable = () => {
    let check = true;

    bulletArray.forEach((item) => {
      if (item.trim()) {
        check = false;
      }
    });

    return check;
  };

  const handleQuestionCardDelete = (id) => {
    let temp = [...bulletArray];

    temp.splice(id, 1);
    setBulletArray(temp);
  };

  const onQuestionEdit = (event, id) => {
    let temp = event.target.value;
    let sentences = temp.split("\n");

    let tempAnswers = [...bulletArray];

    if (sentences.length <= 1) {
      tempAnswers[id] = event.target.value;
      setBulletArray(tempAnswers);
    }
  };

  const handlePaste = (event, id) => {
    let temp = event.clipboardData
      ? event.clipboardData.getData("text")
      : event.target.value;
    let sentences = temp.split("\n");

    let tempAnswers = [...bulletArray];

    if (sentences.length > 1) {
      let count = 0;
      sentences.forEach((sentence) => {
        if (sentence.trim()) {
          tempAnswers.splice(id + count, 0, sentence.trim());
          count += 1;
        }
      });
    }
    setBulletArray(tempAnswers);
  };

  const handleBulletInputEnter = (event, id) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      let tempAnswers = [...bulletArray];

      tempAnswers.splice(id + 1, 0, "");

      setBulletArray(tempAnswers);
      setEnterComplete({
        status: true,
        identifier: questionId,
        index: id + 1,
      });
    }
  };

  const onQuizDone = () => {
    setIsLoading(true);
    onSubmit();
  };

  const getQuizAnswerHtml = () => {
    if (questionData.type === "text") {
      return (
        <div
          className="quiz-text-input-frame"
          id={`text-answer${questionData.id}`}
        >
          <UserChatBubble
            key={uuidv4()}
            message={
              textInputValue.getCurrentContent().hasText()
                ? convertToMarkdown(textInputValue)
                : ""
            }
            showFeedback={false}
          />
        </div>
      );
    } else if (
      questionData.type === "bullet" ||
      questionData.type === "newbullet"
    ) {
      return (
        <div
          className="quiz-option-input-frame"
          id={`table-answer${questionData.id}`}
        >
          <UserChatBubble
            key={uuidv4()}
            message={bulletArray.map((text) => `• ${text}\n`)}
            showFeedback={false}
          />
        </div>
      );
    } else if (questionData.type === "radio") {
      return (
        <div
          className="quiz-option-input-frame"
          id={`checkbox-answer${questionData.id}`}
        >
          {options.map((optionId) => (
            <UserChatBubble
              key={uuidv4()}
              message={
                optionsMap.current[optionId] ? optionsMap.current[optionId] : ""
              }
              showFeedback={false}
            />
          ))}
        </div>
      );
    } else if (questionData.type === "checkbox") {
      return (
        <div
          className="quiz-option-input-frame"
          id={`checkbox-answer${questionData.id}`}
        >
          <UserChatBubble
            key={uuidv4()}
            message={options.map((optionId) =>
              optionsMap.current[optionId]
                ? `✓ ${optionsMap.current[optionId]}\n`
                : ""
            )}
            showFeedback={false}
          />
        </div>
      );
    } else if (questionData.type === "table") {
      return (
        <div
          className="quiz-option-input-frame"
          id={`table-answer${questionData.id}`}
        >
          {tableInputValue.map((row) => (
            <UserChatBubble
              key={uuidv4()}
              message={row.map((column, index) =>
                row.length - 1 === index ? column : `${column} | `
              )}
              showFeedback={false}
            />
          ))}
        </div>
      );
    } else if (questionData.type === "description") {
      return (
        <div
          className="quiz-option-input-frame"
          id={`table-answer${questionData.id}`}
        >
          {tableInputValue.map((row) => (
            <UserChatBubble
              key={uuidv4()}
              message={row.map((column, index) =>
                row.length - 1 === index ? column : `${column} | `
              )}
              showFeedback={false}
            />
          ))}
        </div>
      );
    } else if (questionData.type === "matching") {
      return (
        <div
          className="quiz-option-input-frame"
          id={`match-answer${questionData.id}`}
        >
          <UserChatBubble
            key={uuidv4()}
            // avatarIcon={UserAvatarIcon}
            message={"Matched items recorded"}
            showFeedback={false}
          />
        </div>
      );
    } else if (questionData.type === "group") {
      return (
        <div
          className="quiz-option-input-frame"
          id={`group-answer${questionData.id}`}
        >
          <UserChatBubble
            key={uuidv4()}
            message={"Grouped items recorded"}
            showFeedback={false}
          />
        </div>
      );
    }
  };

  const onGroupAnswerSubmit = () => {
    onAnswerInput(groupAnswersArray, "group", questionId);
    setIsAnswered(true);
  };

  const getAnswerSubmitHtml = (questionType) => {
    if (questionType === "text") {
      return (
        <div className="quiz-text-input-action">
          <button
            disabled={
              textInputValue.getCurrentContent() &&
              textInputValue.getCurrentContent().hasText()
                ? false
                : true
            }
            className="send-button"
            onClick={onTextAnswerSubmit}
            type="button"
          >
            <i className="icon-send" />
          </button>
        </div>
      );
    } else if (questionType === "bullet" || questionType === "newbullet") {
      return (
        <div className="quiz-text-input-action">
          <button
            disabled={shouldBulletSubmitDisable()}
            className="send-button"
            onClick={onBulletAnswerSubmit}
            type="button"
          >
            <i className="icon-send" />
          </button>
        </div>
      );
    } else if (questionType === "table") {
      let tableLength = 0;
      tableInputValue.forEach((row) => {
        tableLength += row.filter((cell) => cell !== "").length;
      });
      return (
        <div className="quiz-table-input-action">
          <button
            disabled={tableLength > 0 ? false : true}
            className="send-button"
            onClick={onTableAnswerSubmit}
            type="button"
          >
            <i className="icon-send" />
          </button>
        </div>
      );
    } else if (questionType === "checkbox") {
      return (
        <div className="quiz-text-input-action">
          <button
            disabled={options.length > 0 ? false : true}
            className="send-button"
            onClick={onCheckboxAnswerSubmit}
            type="button"
          >
            <i className="icon-send" />
          </button>
        </div>
      );
    } else if (questionType === "radio") {
      return (
        <div className="quiz-text-input-action">
          <button
            disabled={options.length > 0 ? false : true}
            className="send-button"
            onClick={onRadioAnswerSubmit}
            type="button"
          >
            <i className="icon-send" />
          </button>
        </div>
      );
    } else if (questionType === "description") {
      let tableLength = 0;
      tableInputValue.forEach((row) => {
        tableLength += row.filter((cell) => cell !== "").length;
      });
      return (
        <div className="quiz-table-input-action">
          <button
            className="send-button"
            disabled={tableLength > 0 ? false : true}
            onClick={onTableAnswerSubmit}
            type="button"
          >
            <i className="icon-send" />
          </button>
        </div>
      );
    } else if (questionType === "matching") {
      return (
        <div className="quiz-table-input-action">
          <button
            className="send-button"
            onClick={onMatchAnswerSubmit}
            type="button"
          >
            <i className="icon-send" />
          </button>
        </div>
      );
    } else if (questionType === "group") {
      return (
        <div className="quiz-table-input-action">
          <button
            className="send-button"
            onClick={onGroupAnswerSubmit}
            type="button"
          >
            <i className="icon-send" />
          </button>
        </div>
      );
    }
  };

  const getQuizInputHtml = () => {
    if (questionData.type === "text" || questionData.type === "essay") {
      return (
        <div
          className="quiz-text-input-frame"
          id={`text-input${questionData.id}`}
        >
          {/* <TextInput
            value={textInputValue}
            inputChange={(e) => onTextChange(e.target.value)}
          /> */}
          <MarkdownEditor
            key={questionData.id}
            id={questionData.id}
            value={textInputValue}
            onChange={(value) => onTextChange(value)}
            placeholder={
              questionData.is_paragraph === true
                ? "To maximize scoring result, please answer in paragraph format."
                : "To maximize scoring result, please answer in bulleted or numbered list."
            }
            isQuizAnswer
          />
          <div className="quiz-text-input-action">
            <button
              disabled={
                textInputValue.getCurrentContent() &&
                textInputValue.getCurrentContent().hasText()
                  ? false
                  : true
              }
              className="send-button"
              onClick={onTextAnswerSubmit}
              type="button"
            >
              <i className="icon-send" />
            </button>
          </div>
        </div>
      );
    } else if (
      questionData.type === "bullet" ||
      questionData.type === "newbullet"
    ) {
      return (
        <div
          className="quiz-text-input-frame"
          id={`bullet-input${questionData.id}`}
        >
          <BulletEditor
            identifier={questionId}
            data={bulletArray}
            handleCardDelete={handleQuestionCardDelete}
            onInputEdit={onQuestionEdit}
            handlePaste={handlePaste}
            handleKeyPress={handleBulletInputEnter}
            handleKeyUp={handleBulletInputEnter}
            shouldAutoFocus={false}
          />
          <div className="quiz-text-input-action">
            <button
              disabled={shouldBulletSubmitDisable()}
              className="send-button"
              onClick={onBulletAnswerSubmit}
              type="button"
            >
              <i className="icon-send" />
            </button>
          </div>
        </div>
      );
    } else if (questionData.type === "radio") {
      return (
        <div
          className="quiz-option-input-frame"
          id={`radio-input${questionData.id}`}
        >
          {/* {questionData.options.map((option) => (
            <button className="quiz-option-btn">{option.text}</button>
          ))} */}
          <Radio
            isQuiz
            options={{
              enumOptions: questionData.options.map((item) => {
                return { value: item.id, label: item.text };
              }),
            }}
            id={questionData.id}
            onChange={onRadioAnswerSubmit}
            selectedValues={options}
          />
        </div>
      );
    } else if (questionData.type === "checkbox") {
      return (
        <div
          className="quiz-option-input-frame"
          id={`checkbox-input${questionData.id}`}
        >
          {/* {questionData.options.map((option) => (
            <button className="quiz-option-btn">{option.text}</button>
          ))} */}
          <Checkbox
            isQuiz
            options={{
              enumOptions: questionData.options.map((item) => {
                return { value: item.id, label: item.text };
              }),
            }}
            onChange={onCheckboxChange}
            selectedValues={options}
          />
          <div className="quiz-text-input-action">
            <button
              disabled={options.length > 0 ? false : true}
              className="send-button"
              onClick={onCheckboxAnswerSubmit}
              type="button"
            >
              <i className="icon-send" />
            </button>
          </div>
        </div>
      );
    } else if (questionData.type === "table") {
      return (
        <div
          className="quiz-option-input-frame"
          id={`table-input${questionData.id}`}
        >
          {/* {questionData.options.map((option) => (
          <button className="quiz-option-btn">{option.text}</button>
        ))} */}
          <QuizTableEditor
            spellCheck={false}
            headerValues={
              questionData.answer_key_settings
                ? questionData.answer_key_settings.headers
                : ["", ""]
            }
            columnHeaderValues={
              questionData.answer_key_settings &&
              "column_headers" in questionData.answer_key_settings
                ? questionData.answer_key_settings.column_headers
                : []
            }
            onTableChange={onTableChange}
            rowCount={
              questionData.answer_key_settings &&
              "row_count" in questionData.answer_key_settings
                ? questionData.answer_key_settings.row_count
                : 1
            }
            columnCount={
              questionData.answer_key_settings &&
              "column_count" in questionData.answer_key_settings
                ? questionData.answer_key_settings.column_count
                : 2
            }
          />
          <div className="quiz-table-input-action">
            <button
              // disabled={
              //   textInputValue.getCurrentContent() &&
              //   textInputValue.getCurrentContent().hasText()
              //     ? false
              //     : true
              // }
              className="send-button"
              onClick={onTableAnswerSubmit}
              type="button"
            >
              <i className="icon-send" />
            </button>
          </div>
        </div>
      );
    } else if (questionData.type === "description") {
      return (
        <div
          className="quiz-option-input-frame"
          id={`table-input${questionData.id}`}
        >
          <HeaderDescriptionEditor
            spellCheck={false}
            // disableHeader
            onTableChange={onTableChange}
            // tableValues={JSON.parse(questionData.ideal_answer)}
          />
          <div className="quiz-table-input-action">
            <button
              className="send-button"
              onClick={onTableAnswerSubmit}
              type="button"
            >
              <i className="icon-send" />
            </button>
          </div>
        </div>
      );
    } else if (questionData.type === "matching") {
      return (
        <div
          className="quiz-option-input-frame"
          id={`match-input${questionData.id}`}
        >
          <DragMatch
            headerValues={
              questionData.answer_key_settings
                ? questionData.answer_key_settings.headers
                : ["", ""]
            }
            terms={questionData.terms ? questionData.terms : []}
            definitions={
              questionData.definitions ? questionData.definitions : []
            }
            onChange={onMatchAnswerChange}
          />
          <div className="quiz-table-input-action">
            <button
              className="send-button"
              onClick={onMatchAnswerSubmit}
              type="button"
            >
              <i className="icon-send" />
            </button>
          </div>
        </div>
      );
    }
  };

  return (
    <div className={"bot-quiz-bubble-wrapper"}>
      {isLoading && (
        <div className="bot-bubble">
          {avatarIcon && (
            <div className="bot-chat-avatar-container">
              <img className="bot-avatar-img" src={avatarIcon} alt="WA" />
            </div>
          )}
          <div className="loading-bubble">
            <LoadingAnimation />
          </div>
        </div>
      )}
      {!isLoading && (
        <div>
          <div className="bot-bubble">
            {avatarIcon && (
              <div className="bot-chat-avatar-container">
                <img className="bot-avatar-img" src={avatarIcon} alt="WA" />
              </div>
            )}
            {!isLastQuestion && (
              <div className="bot-quiz-bubble-container">
                {getBotQuestionHtml()}
              </div>
            )}
            {isLastQuestion && (
              <div className="bot-quiz-bubble-container">
                <div className="quiz-question-frame">
                  You are all done. Click Done to submit your answers.
                </div>
              </div>
            )}
          </div>
          {!isLastQuestion && !isAnswered && (
            <div className="bot-quiz-input-container">{getQuizInputHtml()}</div>
          )}
          {!isLastQuestion &&
            !isAnswered &&
            questionData.type === "group" &&
            "subquestions" in questionData && (
              <>
                {questionData.subquestions.map((subque) => (
                  <SubQuestion
                    questionData={subque}
                    onAnswerInput={onSubQuestionAnswerChange}
                    isAnswered={isAnswered}
                  />
                ))}
                <div className="bot-quiz-input-container">
                  {getAnswerSubmitHtml(questionData.type)}
                </div>
              </>
            )}
          {!isLastQuestion && isAnswered && (
            <div className="bot-quiz-input-container right-align">
              {getQuizAnswerHtml()}
            </div>
          )}
          {isLastQuestion && (
            <div className="bot-quiz-input-container">
              <div className="quiz-text-input-frame">
                <Button
                  buttonClass="custom-button primary-button"
                  onClick={onQuizDone}
                >
                  Done
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BotQuizBubble;
