import React from "react";

import history from "../../history";
import LoginForm from "../../components/LoginForm";
import Snackbar from "../../components/Snackbar";
import AuthContext from "../../context/auth";
import BrandLogo from "../../assets/images/logo-2.png";
import BackgroundImage from "../../assets/images/landing-img.svg";

import "./style.css";

class Login extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      snackbar: {
        isOpen: false,
        message: "",
        type: "success"
      }
    };
  }

  onLogin = (email, password) => {
    // stop if form is invalid
    if (!(email && password)) {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: JSON.stringify({ email, password })
    };
    const tokens = this.context;
    return fetch(
      window.ADMIN_API + "/api/v1/organisation/tokens?origin=chatbot",
      requestOptions
    )
      .then(res => {
        return res.json();
      })
      .then(json => {
        if (json.status.code === 200) {
          tokens.setAuthTokens(json.data.token);
          // this.setState({
          //   snackbar: {
          //     isOpen: true,
          //     message: "Login successful",
          //     type: "success"
          //   }
          // });
          history.push("/");
        } else {
          this.setState({
            snackbar: {
              isOpen: true,
              message: json.status.message,
              type: "error"
            }
          });
        }
      });
  };

  handleForgotPassword = email => {
    console.log("email", email);
  };

  render() {
    let { snackbar } = this.state;
    return (
      <div className="login-page-container">
        <img className="brand-logo" src={BrandLogo} alt="Noodle Factory" />
        <div className="flex-container">
          <div className="form-container">
            <LoginForm
              onLogin={this.onLogin}
              handleForgotPassword={this.handleForgotPassword}
            />
          </div>
          <div className="image-container">
            <div className="image-background-overlap"></div>
            <img src={BackgroundImage} alt="Noodle Factory" />
          </div>
        </div>
        <Snackbar
          isOpen={snackbar.isOpen}
          primaryMessage={snackbar.message}
          type={snackbar.type}
          onRequestClose={() =>
            this.setState({ snackbar: { ...snackbar, isOpen: false } })
          }
        />
      </div>
    );
  }
}

export default Login;
