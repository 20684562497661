import React, { useState } from "react";
import TextInput from "../TextInput";
import Button from "../Button";

import "./style.css";

const ResetPasswordForm = ({ onResetPassword }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const inputChange = (event, name) => {
    switch (name) {
      case "password":
        setPassword(event.target.value);
        break;
      case "confirmPassword":
        setConfirmPassword(event.target.value);
        break;
      default:
        return;
    }
  };

  const handleKeyPress = event => {
    if (password && confirmPassword && event.key === "Enter") {
      onResetPassword(password, confirmPassword);
    }
  };

  return (
    <div className="reset-password-form-container">
      <div className="form-subheading">RESET YOUR PASSWORD</div>
      <div className="field-container">
        <TextInput
          label={"PASSWORD"}
          inputType="password"
          inputClass="input-nf"
          value={password}
          inputChange={inputChange}
          name="password"
          onKeyPress={handleKeyPress}
        />
      </div>
      <div className="field-container">
        <TextInput
          label={"CONFIRM PASSWORD"}
          inputType="password"
          inputClass="input-nf"
          value={confirmPassword}
          inputChange={inputChange}
          name="confirmPassword"
          onKeyPress={handleKeyPress}
        />
      </div>
      <Button
        buttonClass="primary-button custom-button"
        onClick={() => onResetPassword(password, confirmPassword)}
      >
        RESET PASSWORD
      </Button>
    </div>
  );
};

export default ResetPasswordForm;
