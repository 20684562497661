import React from "react";
import { Route } from "react-router-dom";
import history from "../history";
// import AuthContext from "../context/auth";

import { useClearCache } from "react-clear-cache";

function PrivateRoute({ component: Component, ...rest }) {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  // const { reloaded, setReloaded } = AuthContext();
  // Internet Explorer 6-11
  const isIE = /*@cc_on!@*/ false || !!document.documentMode;

  // Edge 20+
  const isEdge = !isIE && !!window.StyleMedia;
  if (!isLatestVersion && !isEdge && !isIE) {
    // emptyCacheStorage();
  }
  // setReloaded(true);
  return (
    <Route
      history={history}
      {...rest}
      render={matchProps => <Component {...matchProps} />}
    />
  );
}

export default PrivateRoute;
