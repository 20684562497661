export const getUrlParameter = (query) => {
  if (query) {
    let vars = query.split("&");
    let query_string = {};
    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split("=");
      let key = decodeURIComponent(pair[0]);
      let value = decodeURIComponent(pair[1]);
      if (typeof query_string[key] === "undefined") {
        query_string[key] = decodeURIComponent(value);
      } else if (typeof query_string[key] === "string") {
        let arr = [query_string[key], decodeURIComponent(value)];
        query_string[key] = arr;
      } else {
        query_string[key].push(decodeURIComponent(value));
      }
    }
    return query_string;
  }
  return {};
};

export const sortablePrettyDate = (date) => {
  let month = String(date.getUTCMonth() + 1).padStart(2, "0");
  let day = String(date.getUTCDate()).padStart(2, "0");
  let year = date.getUTCFullYear().toString().substr(-2);
  return month + "/" + day + "/" + year;
};

export const prettifyDate = (date, label) => {
  let month = String(date.getUTCMonth() + 1).padStart(2, "0");
  let day = String(date.getUTCDate()).padStart(2, "0");
  let year = date.getUTCFullYear().toString().substr(-2);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return label === "withTime"
    ? `${month}/${day}/${year}, ${hours}:${minutes} ${ampm}`
    : `${day}/${month}/${year}`;
};

export const copyStringToClipboard = (str) => {
  // Create new element
  var el = document.createElement("textarea");
  // Set value (string to be copied)
  el.value = str;
  // Set non-editable to avoid focus and move outside of view
  el.setAttribute("readonly", "");
  el.style = { position: "absolute", left: "-9999px" };
  document.body.appendChild(el);
  // Select text inside element
  el.select();
  // Copy text to clipboard
  document.execCommand("copy");
  // Remove temporary element
  document.body.removeChild(el);
};

export const convertToBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const base64MimeType = (encoded) => {
  var result = null;

  if (typeof encoded !== "string") {
    return result;
  }

  var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

  if (mime && mime.length) {
    result = mime[1];
  }

  return result;
};

export const truncate = (str, n) => {
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
};

export const linkify = (str) => {
  if (typeof str === "string") {
    let urlPattern = /https?:\/\/(?!(www\.)?(youtube)|(youtu)|(vimeo)|[^" ]*(?:jpg|jpeg|png|gif|mp4))[^" ]+/gim;
    let imgPattern = /(http(s?):)([/|.%|\w|\s|-])*\.(?:jpg|jpeg|gif|png)/gim;
    let videoPattern = /(http(s?):)([/|.|\w|\s|-])*\.(?:mp4)/gim;
    let pseudoUrlPattern = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    let emailAddressPattern = /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/gim;
    let youtubePattern = /(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/(watch\?v=|)(\S+)/gim;
    let vimeoPattern = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/gim;
    return str
      .replace(urlPattern, '<a target="_blank" href="$&">$&</a>')
      .replace(
        imgPattern,
        '<a href="$&" target="_blank"> <img src="$&" alt="$&"/> </a>'
      )
      .replace(
        videoPattern,
        '<video controls> <source src="$&" type="video/mp4"> </video>'
      )
      .replace(pseudoUrlPattern, '$1<a target="_blank" href="http://$2">$2</a>')
      .replace(emailAddressPattern, '<a href="mailto:$&">$&</a>')
      .replace(
        youtubePattern,
        '<iframe src="https://www.youtube.com/embed/$8" allowfullscreen> </iframe>'
      )
      .replace(
        vimeoPattern,
        '<iframe title="vimeo-player" src="https://player.vimeo.com/video/$4" frameborder="0" allowfullscreen></iframe>'
      );
  } else {
    return str;
  }
};

export const getNRandomElements = (arr, n) => {
  let result = new Array(n),
    len = arr.length,
    taken = new Array(len);
  if (n > len)
    throw new RangeError("getRandom: more elements taken than available");
  while (n--) {
    let x = Math.floor(Math.random() * len);
    result[n] = arr[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
};

export const getNRandomArrayIndices = (arr, n) => {
  let result = new Array(n),
    len = arr.length,
    taken = new Array(len);
  if (n > len)
    throw new RangeError("getRandom: more elements taken than available");
  while (n--) {
    let x = Math.floor(Math.random() * len);
    result[n] = x in taken ? taken[x] : x;
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
};

