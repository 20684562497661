import React from "react";

import "./style.css";

const ChatInput = ({
  placeholder,
  isDisabled,
  onKeyPress,
  onSend,
  value,
  onChange,
  avatarIcon,
  onCustomButtonClick,
  customButtonData,
  widgetPosition,
}) => {
  return (
    <div
      className={
        isDisabled
          ? "chat-input-container chat-input-container-disabled"
          : "chat-input-container"
      }
      style={{
        borderRadius:
          widgetPosition === "right"
            ? "8px 10px 34px 80px"
            : "8px 10px 80px 34px",
        padding:
          widgetPosition === "right"
            ? "12px 12px 16px 24px"
            : "12px 24px 16px 16px",
      }}
    >
      {/* <div className="avatar-container">
        <img src={avatarIcon} alt="User" />
      </div> */}
      <div className="input-container">
        <input
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="input"
          placeholder={placeholder}
          disabled={isDisabled}
          type="text"
          onKeyPress={onKeyPress}
          // rows="1"
        />
      </div>
      <div className="action-container">
        <button
          className="send-button"
          onClick={onSend}
          type="button"
          disabled={!value}
        >
          <i className="icon-send" />
        </button>
        {customButtonData &&
          customButtonData.length > 0 &&
          customButtonData.map((button) => (
            <button
              className="admin-custom-btn"
              title={button.label}
              onClick={(e) => {
                e.stopPropagation();
                onCustomButtonClick(button);
              }}
              type="button"
            >
              {button.label}
            </button>
          ))}
      </div>
    </div>
  );
};

export default ChatInput;
