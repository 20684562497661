import React, { Children } from "react";
import PropTypes from "prop-types";
import Checkbox from "../Checkbox";
import Moment from "react-moment";
import "./style.css";
import { linkify, prettifyDate } from "../../utils/formatFunctions";

const SessionItem = ({ content, onClick }) => (
  <React.Fragment>
    <div
      className="session-container"
      onClick={() => onClick(content.last_log._id, content.session_id)}
    >
      {/* <button className="option-delete-btn" onClick={onDelete}>
        <i className="icon-star" />
      </button> */}
      <span className="session-content">
        {"You: " +
          (content.last_log !== null &&
          content.last_log.question &&
          content.last_log.question.text
            ? content.last_log.question.text
            : "")}
      </span>
      <span className="session-time">
        <Moment format="DD/MM/YYYY HH:mm" utc local>
          {content.updated_at}
        </Moment>
      </span>
    </div>
    {/* <hr class="session-separator"></hr> */}
  </React.Fragment>
);

SessionItem.propTypes = {
  content: PropTypes.object,
};

export default SessionItem;
