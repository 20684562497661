import React from "react";

import "./style.css";

const ImagePreview = (props) => {
  return (
    <a
      className="image-preview-anchor"
      href={props.src}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        className="image-preview-img"
        alt={props.altText ? props.altText : "Image"}
        src={props.src}
      />
    </a>
  );
};

export default ImagePreview;
