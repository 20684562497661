import React from "react";
import Moment from "react-moment";

import "./style.css";

const BotChatBubble = ({
  messageId,
  message,
  messageTime,
  feedback,
  showFeedback,
  recordFeedback,
}) => {
  const bubbleRender = (element) => {
    return (
      <div className="bot-chat-bubble-container">
        <div className="bot-chat-message-container">
          {typeof element !== "object" ? (
            <div
              id={messageId}
              className="bot-chat-message"
              dangerouslySetInnerHTML={{
                __html: element.replace(/\^/gim, "<br>"),
              }}
            ></div>
          ) : (
            <div id={messageId} className="bot-chat-message">
              {element}
            </div>
          )}

          <div className="bot-chat-time">
            <Moment format="DD/MM/YYYY HH:mm" utc local>
              {new Date()}
            </Moment>
          </div>
        </div>
        {showFeedback && (
          <div className="bot-chat-feedback-container">
            <i
              className={`icon-thumbs-up bot-chat-feedback-icon${
                feedback === 1 ? " bot-chat-feedback-icon-selected" : ""
              }`}
              onClick={() => recordFeedback(1, messageId)}
            />
            <i
              className={`icon-thumbs-down bot-chat-feedback-icon${
                feedback === 0 ? " bot-chat-feedback-icon-selected" : ""
              }`}
              onClick={() => recordFeedback(0, messageId)}
            />
          </div>
        )}
      </div>
    );
  };

  if (
    message &&
    message.toString() &&
    ((message.toString().match(/<a[\s]+([^>]+)>((?:.(?!<\/a>))*.)<\/a>/) &&
      message.toString().match(/<img.*?src="(.*?)"[^>]+>/)) ||
      message.toString().match(/<iframe.*>.*<\/iframe>/))
  ) {
    let matchedString = message
      .toString()
      .split(/(<a.*>.*<\/a>)|(<iframe.*>.*<\/iframe>)/g);
    return matchedString.map((element, index) => {
      if (element && element.trim()) {
        if (
          element.match(/<img.*?src="(.*?)"[^>]+>/) ||
          element.match(/<iframe.*>.*<\/iframe>/)
        ) {
          return (
            <div className="bot-chat-bubble-container" key={index}>
              <div className="bot-chat-message-container">
                {typeof element !== "object" ? (
                  <div
                    id={messageId}
                    className="bot-chat-media-message"
                    dangerouslySetInnerHTML={{
                      __html: element.replace(/\^/gim, "<br>"),
                    }}
                  ></div>
                ) : (
                  <div id={messageId} className="bot-chat-media-message">
                    {element}
                  </div>
                )}

                <div className="bot-chat-time">
                  <Moment format="DD/MM/YYYY HH:mm" utc local>
                    {new Date()}
                  </Moment>
                </div>
              </div>
              {showFeedback && (
                <div className="bot-chat-feedback-container">
                  <i
                    className={`icon-thumbs-up bot-chat-feedback-icon${
                      feedback === 1 ? " bot-chat-feedback-icon-selected" : ""
                    }`}
                    onClick={() => recordFeedback(1, messageId)}
                  />
                  <i
                    className={`icon-thumbs-down bot-chat-feedback-icon${
                      feedback === 0 ? " bot-chat-feedback-icon-selected" : ""
                    }`}
                    onClick={() => recordFeedback(0, messageId)}
                  />
                </div>
              )}
            </div>
          );
        } else {
          return bubbleRender(element);
        }
      }
    });
  } else {
    return bubbleRender(message);
  }
};

export default BotChatBubble;
