import React from "react";

import Moment from "react-moment";

import "./style.css";

const UserChatBubble = ({
  avatarIcon,
  message,
  messageTime,
  isButtonContainer,
}) => {
  return (
    <div className="user-chat-bubble-container">
      <div className="user-chat-message-container">
        {!isButtonContainer && (
          <div className="user-chat-message">{message}</div>
        )}
        {isButtonContainer &&
          (typeof message !== "object" ? (
            <div
              className="user-button-container"
              dangerouslySetInnerHTML={{
                __html: message.replace(/\^/gim, "<br>"),
              }}
            ></div>
          ) : (
            <div className="user-button-container">{message}</div>
          ))}
        <div className="user-chat-time">
          {messageTime ? (
            <Moment format="DD/MM/YYYY HH:mm" utc local>
              {new Date()}
            </Moment>
          ) : (
            ""
          )}
        </div>
      </div>
      {/* <div className="user-chat-avatar-container">
        <img src={avatarIcon} alt="WR" />
      </div> */}
    </div>
  );
};

export default UserChatBubble;
