import React, { Children } from "react";

import "./style.css";

const Card = ({ children, className, onClick }) => {
  return (
    <div className={`shadow-card ${className}`} onClick={onClick}>
      {Children.toArray(children)}
    </div>
  );
};

export default Card;
