export function authHeader(authToken) {
  if (authToken) {
    return {
      Authorization: "Widget " + authToken,
      "Content-Type": "application/json",
      Accept: "application/json"
    };
  } else {
    return {};
  }
}
export const sleep = ms => new Promise(r => setTimeout(r, ms));
