import React, { useState } from "react";

import Form from "../../components/Form";
import { linkify } from "../../utils/formatFunctions";

import "./style.css";
import { authHeader } from "../../helpers/auth-header";

const dummyMessage =
  "Hi there! 👋 I am Walter. Please help me with your details so that I can assist you better. No information will be used to spam you. I am a good secret keeper 🤫";

const dummyLayout = {
  formSchema: {
    type: "object",
    properties: {
      name: { type: "string", title: "Name" },
      email: { type: "string", title: "Email" },
      address: { type: "string", title: "Address" },
    },
    required: ["name", "email"],
  },
  uiSchema: {
    name: { "ui:widget": "text" },
    email: { "ui:widget": "email" },
  },
};

const UserDetailsForm = ({
  authToken,
  prefilledFormData,
  headerMessage,
  formLayout,
  informSubmission,
  hideClose,
  closePopup,
  headerIcon,
  isTest,
  hideHeaderAvatar,
  customBotAvatar
}) => {
  // const [formLayout, setFormLayout] = useState(dummyLayout);
  // const [headerMessage, setHeaderMessage] = useState(dummyMessage);

  const handleFormSubmit = (formId, formDetails) => {
    if (formDetails === undefined) {
      informSubmission();
    } else if (
      formDetails.formData &&
      Object.keys(formDetails.formData).length > 0
    ) {
      fetch(window.CHAT_API + "/api/v1/widget/widget-users", {
        method: "POST",
        headers: authHeader(authToken),
        body: JSON.stringify({
          form_data: formDetails.formData,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          if (json.status.code === 200) {
          } else {
          }
        });
    }
    informSubmission();
  };

  return (
    <div className="user-details-form-container chat-wrapper">
      {!hideClose && (
        <button
          type="button"
          className="chat-popup-close-btn user-details-form-close"
          onClick={closePopup}
        >
          <i className="icon-close" title="close"></i>
        </button>
      )}
      <div className="form-header">
        <div className="message-header">
          {!hideHeaderAvatar && (
            <div className="header-avatar-wrapper">
              <img src={customBotAvatar ? customBotAvatar : headerIcon} alt="Hey!" />
            </div>
          )}
          <div
            className="message-cotainer"
            dangerouslySetInnerHTML={{ __html: linkify(headerMessage) }}
          ></div>
        </div>
        {/* <div className="wave-wrapper">
          <img src={HeaderWave} alt="" />
        </div> */}
      </div>
      <Form
        isUserDetailsForm={true}
        onSubmit={handleFormSubmit}
        formSchema={formLayout.formSchema}
        uiSchema={formLayout.uiSchema}
        submitButtonLabel="Start Chat"
        formId={"12345"}
        prefilledFormData={prefilledFormData}
      />
    </div>
  );
};

export default UserDetailsForm;
