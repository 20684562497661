import React, { useEffect } from "react";

import "./style.css";

const Snackbar = ({
  isOpen,
  onRequestClose,
  primaryMessage,
  secondaryMessage,
  type,
  closeTiming,
}) => {
  useEffect(() => {
    closeSnackbar();
  }, [isOpen === true]);

  const getSnackbarClass = () => {
    switch (type) {
      case "success":
        return "snackbar-success";
      case "error":
        return "snackbar-error";
      case "warning":
        return "snackbar-warning";
      case "info":
        return "snackbar-info";
      case "bubble":
        return "snackbar-bubble";
      default:
        return "snackbar-success";
    }
  };

  const closeSnackbar = () => {
    setTimeout(() => {
      onRequestClose();
    }, closeTiming);
  };

  if (isOpen) {
    return (
      <div className={`snackbar-container ${getSnackbarClass()}`}>
        <div className={`snackbar-message`}>
          <div className="snackbar-primary-message">{primaryMessage}</div>
          <div className="snackbar-secondary-message">{secondaryMessage}</div>
        </div>
        <button
          type="button"
          className="snackbar-close"
          onClick={onRequestClose}
        >
          <i className="icon-close" />
        </button>
      </div>
    );
  } else {
    return null;
  }
};

Snackbar.defaultProps = {
  closeTiming: 3000,
  isOpen: false,
  primaryMessage: "I am a snackbar",
  type: "success",
};

export default Snackbar;
