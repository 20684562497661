import React, { useEffect } from "react";

import TextInput from "../TextInput";
import MarkdownEditor from "../MarkdownEditor";
import Button from "../Button";
import "./style.css";

const InputCard = ({
  id,
  type,
  value,
  hideClose,
  onClose,
  inputChange,
  placeholder,
  handleKeyPress,
  enableSecondaryInput,
  secondaryInputValue,
  secondaryInputChange,
  useTextArea,
  primaryLabel,
  secondaryLabel,
  handleAddButton,
  required,
  usePrimaryMarkdown,
  useSecondaryMarkdown,
  numberLabel,
  onPrimaryPaste,
  tabIndex,
  autoFocus,
}) => {
  return (
    <a className="input-card-wrapper-anchor">
      <div
        className={"input-card"}
        style={primaryLabel ? { marginLeft: "24px" } : {}}
      >
        {type === "edit" && (
          <div className="input-card-edit-wrapper">
            <div className="flex-container primary-section">
              {primaryLabel && (
                <div className="primary-section-label">
                  <span>{primaryLabel}</span>
                </div>
              )}
              {numberLabel && (
                <div className="number-section-label">
                  <span>{numberLabel}</span>
                </div>
              )}
              {usePrimaryMarkdown && (
                <MarkdownEditor value={value} id={id} onChange={inputChange} />
              )}
              {!usePrimaryMarkdown && (
                <TextInput
                  id={id}
                  autoFocus={autoFocus}
                  required={required}
                  inputType="text"
                  inputClass="input-nf"
                  placeholder={placeholder}
                  value={value}
                  inputChange={inputChange}
                  name={id}
                  useTextArea={useTextArea}
                  onPaste={onPrimaryPaste}
                  tabIndex={tabIndex}
                  onKeyPress={handleKeyPress}
                />
              )}
            </div>
            {enableSecondaryInput && (
              <div className="flex-container secondary-section">
                <div className="secondary-section-label">
                  <span>{secondaryLabel}</span>
                </div>
                {useSecondaryMarkdown && (
                  <MarkdownEditor
                    value={secondaryInputValue}
                    id={id}
                    onChange={secondaryInputChange}
                  />
                )}
                {!useSecondaryMarkdown && (
                  <TextInput
                    required={required}
                    inputType="text"
                    inputClass="input-nf"
                    placeholder={placeholder}
                    value={secondaryInputValue}
                    inputChange={secondaryInputChange}
                    name={id}
                    useTextArea={useTextArea}
                    onPaste={onPrimaryPaste}
                  />
                )}
              </div>
            )}
            {!hideClose && (
              <button
                className="input-card-close-button"
                onClick={() => onClose(id)}
              >
                <i className="icon-delete input-card-close-icon" />
              </button>
            )}
          </div>
        )}
        {type === "add" && (
          <div className="input-card-add-wrapper">
            <>
              {usePrimaryMarkdown && (
                <MarkdownEditor
                  key={id}
                  value={value}
                  id={id}
                  onChange={inputChange}
                />
              )}
              {!usePrimaryMarkdown && (
                <div className="flex-container primary-add-section">
                  {primaryLabel && (
                    <div className="primary-section-label">
                      <span>{primaryLabel}</span>
                    </div>
                  )}

                  <TextInput
                    required={required}
                    inputType="text"
                    inputClass="input-nf"
                    placeholder={placeholder}
                    value={value}
                    inputChange={inputChange}
                    name={id}
                    useTextArea={useTextArea}
                    onKeyPress={handleKeyPress}
                  />
                </div>
              )}
            </>
            {enableSecondaryInput && (
              <div className="flex-container secondary-add-section">
                <div className="secondary-section-label">
                  <span>{secondaryLabel}</span>
                </div>
                {useSecondaryMarkdown && (
                  <MarkdownEditor
                    key={id}
                    value={secondaryInputValue}
                    id={id}
                    onChange={secondaryInputChange}
                  />
                )}
                {!useSecondaryMarkdown && (
                  <TextInput
                    required={required}
                    inputType="text"
                    inputClass="input-nf"
                    placeholder={placeholder}
                    value={secondaryInputValue}
                    inputChange={secondaryInputChange}
                    name={id}
                    useTextArea={useTextArea}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
      {handleAddButton && (
        <div className="add-button-container">
          <Button
            buttonClass="secondary-button custom-button"
            onClick={handleAddButton}
          >
            Add
          </Button>
        </div>
      )}
    </a>
  );
};

export default InputCard;
