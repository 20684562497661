import React, { useEffect, useState } from "react";

import SuggestionButton from "../SuggestionButton";

const CorrectAnswerRecord = ({
  questionId,
  questionType,
  question,
  answers,
  submittedAnswers,
  questionScore,
  userScore,
  answerKeySettings,
  showAllScores,
  subquestionsData,
  questionNumber,
  conditions,
  onConditionButtonClick,
  displayedQuestionsId,
  submissionData,
}) => {
  const getTableDisplay = (data, noHeader, answerKeySettings) => {
    let temp =
      data && typeof data === "string" && JSON.parse(data)
        ? JSON.parse(data)
        : data && data.length > 0
        ? data
        : [];
    return (
      <table>
        {!noHeader && (
          <thead>
            <tr>
              {answerKeySettings &&
                answerKeySettings.headers &&
                answerKeySettings.headers.map((column) => <th>{column}</th>)}
            </tr>
          </thead>
        )}
        <tbody>
          {temp.map((row, index) => (
            <tr>
              {answerKeySettings && answerKeySettings.column_headers && (
                <th>
                  {"column_headers" in answerKeySettings
                    ? answerKeySettings.column_headers[index]
                    : ""}
                </th>
              )}
              {row.map((column) => (
                <td>{column}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const getMatchDisplay = (data, referenceData) => {
    return (
      <table>
        <thead>
          <tr>
            {answerKeySettings &&
              answerKeySettings.headers &&
              answerKeySettings.headers.map((column) => <th>{column}</th>)}
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr>
              <td>{row.term}</td>
              <td>{row.definition}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const getBulletDisplay = (data) => {
    return (
      <ul>
        {data.map((item) => (
          <li>{item.text}</li>
        ))}
      </ul>
    );
  };

  const divideArray = (data, controlColumnCount) => {
    let temp = [...data];
    const result = new Array(Math.ceil(temp.length / controlColumnCount))
      .fill()
      .map((_) => temp.splice(0, controlColumnCount));
    return result;
  };

  const getNewTableDisplay = (data, answerKeySettings) => {
    return (
      <table className="quiz-table-answer-table">
        <thead>
          <tr>
            {answerKeySettings &&
              answerKeySettings.headers &&
              answerKeySettings.headers.map((column) => (
                <th className="quiz-table-answer-cell">{column}</th>
              ))}
          </tr>
        </thead>

        <tbody>
          {divideArray(data, answerKeySettings.column_count).map(
            (row, index) => (
              <tr>
                <th className="quiz-table-header-cell">
                  {"column_headers" in answerKeySettings
                    ? answerKeySettings.column_headers[index]
                    : ""}
                </th>
                {row.map((column) => (
                  <td className="quiz-table-answer-cell">{column.text}</td>
                ))}
              </tr>
            )
          )}
        </tbody>
      </table>
    );
  };

  const getSubAnswerDisplay = (data, subQuestionNumber) => {
    return (
      <React.Fragment>
        <div className="half-clearfix" />
        <div className="question-row side-padding">
          <h3 className="page-main-heading">
            Question {questionNumber}.{subQuestionNumber}{" "}
            {(data.questionScore !== undefined ||
              data.userScore !== undefined) &&
              (data.questionScore !== 0 ||
                data.questionScore !== "0" ||
                data.questionScore !== "0.00") &&
              showAllScores !== false &&
              data.questionType !== "group" && (
                <span>
                  (Score:{" "}
                  {submissionData.result_settings &&
                  submissionData.result_settings.show_scores_in_percentage
                    ? data.userScore === "-"
                      ? "Not available"
                      : Math.floor(
                          (data.userScore / data.questionScore) * 100
                        ) + "%"
                    : data.userScore === "-"
                    ? "Not available"
                    : data.userScore + "/" + data.questionScore}
                  )
                </span>
              )}
          </h3>
          <div
            className="answer-table-question answer-table-subquestion"
            dangerouslySetInnerHTML={{ __html: data.question }}
          />
        </div>
        <div className="half-clearfix" />
        {data.questionType !== "group" && (
          <div className="answer-row flex-container">
            <div className="flex-one side-padding">
              <h3 className="page-subheading">Recommended Answer</h3>
              {data.questionType === "description" &&
                data.answers.map((answer, localIndex) => (
                  <div className="answer-table-question" key={localIndex}>
                    {getTableDisplay(
                      "text" in answer ? answer.text : "",
                      data.questionType === "description",
                      data.answerKeySettings
                    )}
                  </div>
                ))}
              {data.questionType === "table" &&
                data.answers.map((answer, localIndex) => (
                  <div className="answer-table-question" key={localIndex}>
                    {getNewTableDisplay(
                      "bullets" in answer ? answer.bullets : [],
                      data.answerKeySettings
                    )}
                  </div>
                ))}
              {data.questionType === "matching" &&
                data.answers.map((answer, localIndex) => (
                  <div className="answer-table-question" key={localIndex}>
                    {getMatchDisplay("matches" in answer ? answer.matches : [])}
                  </div>
                ))}
              {questionType === "newbullet" &&
                answers.map((answer, localIndex) => (
                  <div className="answer-table-question" key={localIndex}>
                    {getBulletDisplay(
                      "bullets" in answer ? answer.bullets : []
                    )}
                  </div>
                ))}
              {data.questionType !== "table" &&
                data.questionType !== "description" &&
                data.questionType !== "matching" &&
                questionType !== "newbullet" &&
                data.answers.map((answer, localIndex) => (
                  <div className="answer-table-question" key={localIndex}>
                    {"text" in answer ? answer.text : ""}
                  </div>
                ))}
            </div>
            <div className="flex-one side-padding">
              <h3 className="page-subheading">Your Answer</h3>
              {(data.questionType === "table" ||
                data.questionType === "description") &&
                data.submittedAnswers.map((answer, localIndex) => (
                  <div className="answer-table-question" key={localIndex}>
                    {getTableDisplay(
                      "text" in answer ? answer.text : "",
                      data.questionType === "description",
                      data.answerKeySettings
                    )}
                  </div>
                ))}
              {data.questionType === "matching" &&
                data.submittedAnswers.map((answer, localIndex) => (
                  <div className="answer-table-question" key={localIndex}>
                    {getMatchDisplay(
                      "matches" in answer ? answer.matches : [],
                      data.answers[localIndex]
                    )}
                  </div>
                ))}
              {data.questionType !== "table" &&
                data.questionType !== "description" &&
                data.questionType !== "matching" &&
                data.submittedAnswers.map((answer, localIndex) => (
                  <div className="answer-table-question" key={localIndex}>
                    {"text" in answer ? answer.text : ""}
                  </div>
                ))}
            </div>
          </div>
        )}
        {conditions &&
          conditions.length > 0 &&
          conditions
            .filter((show) => show.question_id === data.questionId)
            .map((show) => (
              <div className="question-conditions-container">
                {show.message && (
                  <div className="condition-message">
                    <div className="half-clearfix" />
                    <div dangerouslySetInnerHTML={{ __html: show.message }} />
                  </div>
                )}
                {show.buttons && (
                  <div className="condition-buttons">
                    {show.buttons.map((button, index) => (
                      <SuggestionButton
                        key={index.toString()}
                        buttonLabel={button.label}
                        buttonAction={() =>
                          onConditionButtonClick(
                            button,
                            "quiz",
                            displayedQuestionsId,
                            submissionData
                          )
                        }
                      />
                    ))}
                  </div>
                )}
              </div>
            ))}
        <div className="half-clearfix" />
      </React.Fragment>
    );
  };

  return (
    <div className="correct-answer-record-container">
      <div className="question-row side-padding">
        <h3 className="page-main-heading">
          Question {questionNumber}{" "}
          {(questionScore !== undefined || userScore !== undefined) &&
            (questionScore !== 0 ||
              questionScore !== "0" ||
              questionScore !== "0.00") &&
            showAllScores !== false &&
            questionType !== "group" && (
              <span>
                (Score:{" "}
                {submissionData.result_settings &&
                submissionData.result_settings.show_scores_in_percentage
                  ? userScore === "-"
                    ? "Not available"
                    : Math.floor((userScore / questionScore) * 100) + "%"
                  : userScore === "-"
                  ? "Not available"
                  : userScore + "/ " + questionScore}
                )
              </span>
            )}
        </h3>
        <div
          className="answer-table-question"
          dangerouslySetInnerHTML={{ __html: question }}
        />
      </div>
      <div className="half-clearfix" />
      {questionType !== "group" && (
        <div className="answer-row side-padding">
          <div>
            <h3 className="page-subheading">Recommended Answer</h3>
            {questionType === "description" &&
              answers.map((answer, localIndex) => (
                <div className="answer-table-question" key={localIndex}>
                  {getTableDisplay(
                    "text" in answer ? answer.text : "",
                    questionType === "description",
                    answerKeySettings
                  )}
                </div>
              ))}
            {questionType === "table" &&
              answers.map((answer, localIndex) => (
                <div className="answer-table-question" key={localIndex}>
                  {getNewTableDisplay(
                    "bullets" in answer ? answer.bullets : [],
                    answerKeySettings
                  )}
                </div>
              ))}
            {questionType === "matching" &&
              answers.map((answer, localIndex) => (
                <div className="answer-table-question" key={localIndex}>
                  {getMatchDisplay("matches" in answer ? answer.matches : [])}
                </div>
              ))}
            {questionType === "newbullet" &&
              answers.map((answer, localIndex) => (
                <div className="answer-table-question" key={localIndex}>
                  {getBulletDisplay("bullets" in answer ? answer.bullets : [])}
                </div>
              ))}
            {questionType !== "table" &&
              questionType !== "description" &&
              questionType !== "matching" &&
              questionType !== "newbullet" &&
              answers.map((answer, localIndex) => (
                <div className="answer-table-question" key={localIndex}>
                  {"text" in answer ? answer.text : ""}
                </div>
              ))}
          </div>
          <div className="half-clearfix" />
          <div>
            <h3 className="page-subheading">Your Answer</h3>
            {(questionType === "table" || questionType === "description") &&
              submittedAnswers.map((answer, localIndex) => (
                <div className="answer-table-question" key={localIndex}>
                  {getTableDisplay(
                    "text" in answer ? answer.text : "",
                    questionType === "description",
                    answerKeySettings
                  )}
                </div>
              ))}
            {questionType === "matching" &&
              questionType !== "essay" &&
              submittedAnswers.map((answer, localIndex) => (
                <div className="answer-table-question" key={localIndex}>
                  {getMatchDisplay(
                    "matches" in answer ? answer.matches : [],
                    answers[localIndex]
                  )}
                </div>
              ))}
            {questionType !== "table" &&
              questionType !== "description" &&
              questionType !== "matching" &&
              questionType !== "essay" &&
              submittedAnswers.map((answer, localIndex) => (
                <div className="answer-table-question" key={localIndex}>
                  {"text" in answer ? answer.text : ""}
                </div>
              ))}
            {questionType === "essay" && (
              <div className="answer-table-question">
                {submittedAnswers ? submittedAnswers : ""}
              </div>
            )}
          </div>
        </div>
      )}
      {questionType === "group" &&
        subquestionsData &&
        subquestionsData.map((subque, localIndex) =>
          getSubAnswerDisplay(subque, localIndex + 1)
        )}
      {conditions &&
        conditions.length > 0 &&
        conditions
          .filter((show) => show.question_id === questionId)
          .map((show) => (
            <div className="question-conditions-container">
              {show.message && (
                <div className="condition-message">
                  <div className="half-clearfix" />
                  <div dangerouslySetInnerHTML={{ __html: show.message }} />
                </div>
              )}
              {show.buttons && (
                <div className="condition-buttons">
                  {show.buttons.map((button, index) => (
                    <SuggestionButton
                      key={index.toString()}
                      buttonLabel={button.label}
                      buttonAction={() =>
                        onConditionButtonClick(
                          button,
                          "quiz",
                          displayedQuestionsId,
                          submissionData
                        )
                      }
                    />
                  ))}
                </div>
              )}
            </div>
          ))}
      <div className="half-clearfix" />
      <hr />
      <div className="half-clearfix" />
    </div>
  );
};

export default CorrectAnswerRecord;
