import React from "react";

import Button from "../Button";
import "./style.css";

const FormSubmissionMessage = ({
  type,
  heading,
  message,
  buttonLabel,
  buttonAction
}) => {
  return (
    <div className="form-submission-message-page">
      <div className="message-content-wrapper">
        <div className="message-content">
          {type === "success" && <i className="icon-tick" />}
          {type === "error" && <i className="icon-warning" />}
          <div className="heading">{heading}</div>
          <div
            className="form-submission-message"
            dangerouslySetInnerHTML={{
              __html: message
            }}
          ></div>
        </div>
      </div>
      <div className="footer">
        <Button
          buttonClass="custom-button primary-button"
          onClick={buttonAction}
        >
          {buttonLabel}
        </Button>
      </div>
    </div>
  );
};

export default FormSubmissionMessage;
