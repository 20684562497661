import React, { useState } from "react";

import "./style.css";

const FileUpload = (props) => {
  const [fileName, setFileName] = useState("");
  const [inputPlaceholder, setInputPlaceholder] = useState("Choose a file");
  const [isFileSizeError, setIsFileSizeError] = useState(false);

  const toBase64 = (files, callBack) => {
    let file = files[0];
    setFileName(file.name);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      callBack(file, reader.result);
    };
    reader.onerror = function(error) {
      console.log("Error: ", error);
    };
  };

  return (
    <div className="file-uploader-container">
      <div className="file-uploader">
        {fileName ? fileName : inputPlaceholder}
        <input
          type="file"
          required={props.required ? props.required : false}
          onChange={(event) =>
            toBase64(event.target.files, (file, data) => {
              if (!data) {
                setInputPlaceholder("Uploading the file...");
              } else {
                setInputPlaceholder("Choose a file");
              }
              let fileSize = (file.size / (1024 * 1024)).toFixed(2);
              if (fileSize < 10.0) {
                setIsFileSizeError(false);
                props.onChange({
                  data,
                  file_name: file.name,
                });
              } else {
                setIsFileSizeError(true);
              }
            })
          }
        />
        <span className="file-uploader-icon-wrapper">
          <i className="icon-attach-file" />
        </span>
      </div>
      {!isFileSizeError && (
        <div className="info-message">Allowed file size &lt; 10 MB</div>
      )}
      {isFileSizeError && (
        <div className="error-message">
          Cannot upload. Please upload a file &lt; 10 MB
        </div>
      )}
    </div>
  );
};

export default FileUpload;
