import React from "react";
import PropTypes from "prop-types";
import ReactSelect from "react-select";

import "./style.css";

const customStyles = {
  menu: (provided) => ({
    ...provided,
    color: "#07032b",
    fontSize: "0.8rem",
    maxHeight: "280px",
    overflow: "auto",
  }),
  control: (provided) => ({
    ...provided,
    fontSize: "0.8rem",
    color: "#07032b",
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    const color = "#1e4c88";
    return {
      ...styles,
      backgroundColor: isSelected ? color : isFocused ? "#ddf0f9" : null,
      color: isSelected ? "#fff" : "#07032b",
      cursor: isDisabled ? "not-allowed" : "pointer",
      fontWeight: isSelected ? "600" : "500",
    };
  },
};

function Dropdown({
  placeHolder,
  isSearchable,
  options,
  dropdownContainerClassName,
  dropdownListClassName,
  onChange,
  label,
  isMandatory,
  value,
  defaultValue,
  isDisabled,
  isClearable,
}) {
  return (
    <div className={`dropdown_wrapper ${dropdownContainerClassName}`}>
      {/* {label && (
        <label>
          {label}
          {isMandatory && <span style={{ color: "red" }}>*</span>}
        </label>
      )} */}
      <ReactSelect
        isClearable={true}
        menuPosition="fixed"
        styles={customStyles}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        options={options.enumOptions}
        isDisabled={isDisabled}
        placeholder={placeHolder}
        isSearchable={true}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: "#ddf0f9",
            primary: "#1e4c88",
          },
        })}
      />
    </div>
  );
}

Dropdown.propTypes = {
  label: PropTypes.string,
  placeHolder: PropTypes.string,
  isSearchable: PropTypes.bool,
  data: PropTypes.array,
  dropdownContainerClassName: PropTypes.string,
  dropdownListClassName: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  errorMessage: PropTypes.string,
  isFormError: PropTypes.bool,
  isMandatory: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

Dropdown.defaultProps = {
  placeHolder: "",
  isSearchable: false,
  dropdownContainerClassName: "",
  dropdownListClassName: "",
  data: [],
  onChange: (value) => {
    console.dir(value);
  },
  isMandatory: false,
  isFormError: false,
  errorMessage: "",
  isDisabled: false,
};

export default Dropdown;
