import React from "react";
import { useHistory } from "react-router-dom";
import ResetPasswordForm from "../../components/ResetPasswordForm";
import BrandLogo from "../../assets/images/logo-2.png";
import BackgroundImage from "../../assets/images/landing-img.svg";

import "./style.css";

const ResetPassword = () => {
  let history = useHistory();
  const onResetPassword = (password, confirmPassword) => {
    // stop if form is invalid
    if (!(password && confirmPassword)) {
      return;
    }

    // const requestOptions = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "application/json"
    //   },
    //   body: JSON.stringify({ email, password })
    // };

    // return fetch(window.ADMIN_API + "/api/v1/organisation/tokens", requestOptions)
    //   .then(res => {
    //     return res.json();
    //   })
    //   .then(json => {
    //     if (json.status.code === 200) {
    //       localStorage.setItem("user", json.data.token.access_token);
    //       alert("Reset Password Successful!");
    //       history.push("/");
    //     } else {
    //       alert("Invalid username or password!");
    //     }
    //   });
  };

  return (
    <div className="reset-password-page-container">
      <img className="brand-logo" src={BrandLogo} />
      <div className="flex-container">
        <div className="form-container">
          <ResetPasswordForm onResetPassword={onResetPassword} />
        </div>
        <div className="image-container">
          <div className="image-background-overlap"></div>
          <img src={BackgroundImage} />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
