import React from "react";

import "./style.css";

const Checkbox = (props) => {
  return (
    <>
      {props.options &&
        props.options.enumOptions &&
        props.options.enumOptions.map((option, index) => (
          <div className="checkbox-container">
            <input
              // defaultChecked={defaultChecked}
              // defaultValue={defaultValue}
              // disabled={isDisabled}
              id={`${option.label}${index}`}
              type="checkbox"
              className={`custom-checkbox ${
                props.isQuiz ? "absolute-quiz-checkbox" : ""
              }`}
              onClick={
                props.isQuiz
                  ? (event) => {
                      props.onChange(event.target.checked, option.value);
                    }
                  : () => props.onChange(!option.value)
              }
              checked={
                props.isQuiz
                  ? props.selectedValues.includes(option.value)
                  : props.checked
              }
            />
            {!props.isQuiz && (
              <label className="checkbox-label">{option.label}</label>
            )}
            {props.isQuiz && (
              <label
                className={`quiz-checkbox-wrapper ${
                  props.selected === option.value ? "active" : ""
                }`}
                for={`${option.label}${index}`}
              >
                <div
                  className="quiz-checkbox"
                  dangerouslySetInnerHTML={{ __html: option.label }}
                />
              </label>
            )}
          </div>
        ))}
    </>
  );
};

export default Checkbox;
