import React from "react";
import {
  Router,
  Switch,
  Route
  // Link
} from "react-router-dom";
import history from "./history";
import { AuthContext } from "./context/auth";

import LoginRoute from "./routes/LoginRoute";
import PrivateRoute from "./routes/PrivateRoute";

import Login from "./containers/Login";
import ResetPassword from "./containers/ResetPassword";
import NewChat from "./containers/NewChat";


import "./App.css";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authTokens: JSON.parse(localStorage.getItem("user"))
    };
  }

  setAuthTokens = data => {
    this.setState({
      authTokens: data
    });
  };

  setTokens = data => {
    localStorage.setItem("user", JSON.stringify(data));
    this.setAuthTokens(data);
  };

  render() {
    let { authTokens } = this.state;
    return (
      <AuthContext.Provider
        value={{ authTokens, setAuthTokens: this.setTokens }}
      >
        <Router history={history}>
          <Switch>
            <LoginRoute
              exact
              path="/login"
              component={Login}
              isAuth={authTokens}
            />
            <LoginRoute
              exact
              path="/reset-password"
              component={ResetPassword}
              isAuth={authTokens}
            />
            <PrivateRoute exact path="/" component={NewChat} />
            <PrivateRoute path="/chat/:directChatKey" component={NewChat} />

          </Switch>
        </Router>
      </AuthContext.Provider>
    );
  }
}

export default App;
