import React, { useState } from "react";

import Card from "../../components/Card";
import Button from "../../components/Button";

import "./style.css";
import SessionItem from "../../components/SessionItem";

const InitialScreen = ({
  hideClose,
  closePopup,
  headerIcon,
  customBotAvatar,
  isTest,
  sessionData,
  loadSession,
  startNewConversation,
  homeScreenMessage,
  hideHeaderAvatar,
  errorBanner,
  turnOffErrorBanner,
}) => {
  return (
    <div className="initial-screen-container chat-wrapper">
      {!hideClose && (
        <button
          type="button"
          className="chat-popup-close-btn user-details-form-close"
          onClick={closePopup}
        >
          <i className="icon-close" title="close"></i>
        </button>
      )}
      {errorBanner &&
        window.location.hasOwnProperty("ancestorOrigins") &&
        window.location.ancestorOrigins.length > 0 &&
        window.location.ancestorOrigins[0].includes("portal") && (
          <div className={"error-banner error-banner-radius"}>
            <div className="w-calc">
              To test out the student portal, please go to{" "}
              <a
                href={
                  window.location.origin.includes(".staging")
                    ? "https://chatbot.staging.noodlefactory.ai" +
                      `?agent=${
                        this.state.agentIdParameter.split("=")[1]
                      }&through=admin-portal`
                    : window.location.origin.includes("chatbotus.") ||
                      window.location.origin.includes("widgetus.")
                    ? "https://chatbotus.noodlefactory.ai" +
                      `?agent=${
                        this.state.agentIdParameter.split("=")[1]
                      }&through=admin-portal`
                    : "https://chatbot.noodlefactory.ai" +
                      `?agent=${
                        this.state.agentIdParameter.split("=")[1]
                      }&through=admin-portal`
                }
                target="_blank"
                className={"error-banner-a"}
              >
                {window.location.origin.includes(".staging")
                  ? "chatbot.staging.noodlefactory.ai"
                  : window.location.origin.includes("chatbotus.") ||
                    window.location.origin.includes("widgetus.")
                  ? "chatbotus.noodlefactory.ai"
                  : "chatbot.noodlefactory.ai"}
              </a>{" "}
            </div>
            <button
              type="button"
              className="error-banner-close-btn top16px"
              onClick={turnOffErrorBanner}
            >
              <i className="icon-close" title="close"></i>
            </button>
          </div>
        )}
      <div className="form-header">
        <div className="message-header">
          {!hideHeaderAvatar && (
            <div className="header-avatar-wrapper">
              <img
                src={customBotAvatar ? customBotAvatar : headerIcon}
                alt="Hey!"
              />
            </div>
          )}
          {/* <div
            className="message-container"
            dangerouslySetInnerHTML={{ __html: linkify(headerMessage) }}
          ></div> */}
          <div className="message-container">{homeScreenMessage}</div>
        </div>
        {/* <div className="wave-wrapper">
          <img src={HeaderWave} alt="" />
        </div> */}
      </div>

      <div className="card-container">
        <Card className="chat-sessions-card">
          {sessionData.length > 0 && (
            <div className="card-heading left-align">Your conversations</div>
          )}
          {sessionData.length === 0 && (
            <div className="card-heading left-align">
              You don't have any conversations yet. Please click the button to
              start chatting now :)
            </div>
          )}
          {sessionData.map((session, index) => (
            <SessionItem
              content={session}
              onClick={loadSession}
              // onDelete={() => onDeleteBookmark(bookmark._id)}
            ></SessionItem>
          ))}
        </Card>
      </div>
      <Button
        buttonClass="primary-button custom-button"
        onClick={startNewConversation}
      >
        Start a New Conversation
      </Button>
    </div>
  );
};

export default InitialScreen;
