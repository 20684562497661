import React from "react";
import AuthContext from "../context/auth";
import { Route, Redirect } from "react-router-dom";
import history from "../history";

function LoginRoute({ component: Component, isAuth, ...rest }) {
  return (
    <Route
      history={history}
      {...rest}
      render={matchProps =>
        isAuth ? <Redirect to="/" /> : <Component {...matchProps} />
      }
    />
  );
}

export default LoginRoute;
