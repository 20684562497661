import React, { useState } from "react";

import Frown from "../../assets/images/frown.png";
import Sad from "../../assets/images/sad.png";
import Neutral from "../../assets/images/neutral.png";
import Satisfied from "../../assets/images/satisfied.png";
import Happy from "../../assets/images/happy.png";
import "./style.css";

const RatingInput = (props) => {
  const [selectedRating, setSelectedRating] = useState(null);

  const getRatingEmoji = (value) => {
    switch (value) {
      case 1:
        return Frown;
      case 2:
        return Sad;
      case 3:
        return Neutral;
      case 4:
        return Satisfied;
      case 5:
        return Happy;
      default:
        return Happy;
    }
  };

  return (
    <div className="rating-range">
      {props.options.enumOptions.map((option) => (
        <button
          type="button"
          className={`rating-item${
            option.value === selectedRating
              ? " rating-item-active-" + option.label
              : ""
          }`}
          onClick={() => {
            setSelectedRating(option.value);
            props.onChange(option.value);
          }}
        >
          <img
            className="rating-item-img"
            alt={option.label}
            src={getRatingEmoji(option.value)}
          />
        </button>
      ))}
    </div>
  );
};

export default RatingInput;
