import React, { useEffect, useState } from "react";
import Moment from "react-moment";

import ImagePreview from "../ImagePreview";
import StickerPreview from "../StickerPreview";
import VideoPreview from "../VideoPreview";

import infoCirclekImg from "../../assets/images/info-circle-red.svg";
import thumpsDownFilled from "../../assets/images/svg/Thumbs-Down-Filled.svg";
import thumpsUpFilled from "../../assets/images/svg/Thumbs-Up-Filled.svg";
import thumbsDownBlue from "../../assets/images/svg/thumb-down-outline-blue.svg";
import thumbsupBlue from "../../assets/images/svg/thumb-up-outline-blue.svg";
import thumbsupOutlined from "../../assets/images/svg/thumbs-down-outline.svg";
import thumbsdownBlueOutlined from "../../assets/images/svg/thumbs-up-outline.svg";
import LoadingAnimation from "../LoadingAnimation";
import "./style.css";

const BotReply = ({
  replyType,
  data,
  replyTime,
  replyId,
  bookmarkAction,
  showFeedback,
  feedback,
  recordFeedback,
  documentPreviewSetting,
  setDocumentPreview,
  element,
  defaultDisclaimer,
  html,
}) => {
  const [showFavBar, setShowFavBar] = useState(false);
  const [localFeedback, setLocalFeedback] = useState(null);
  useEffect(() => {
    // if (feedbackType && feedbackType.hasOwnProperty("type")) {
    setLocalFeedback(feedback);
    // }
  }, [feedback]);

  const getBotReplyItem = () => {
    if (data.template === "text") {
      // text-reply-from-gpt
      return (
        <>
          <div
            className={
              element &&
              element.context &&
              element.context.botmode === "improvising"
                ? "text-reply-container text-reply-from-gpt" +
                  (data.text.length < 55
                    ? " text-reply-from-gpt-low-content"
                    : "")
                : "text-reply-container"
            }
            onClick={() => {
              setShowFavBar(!showFavBar);
            }}
          >
            <div
              className="text-reply text-reply-list"
              dangerouslySetInnerHTML={{ __html: data.html }}
            ></div>

            {element &&
              element.context &&
              element.context.botmode === "improvising" && (
                <div
                  className="chatInfo-toltip position-relative"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <button className="btn px-2">
                    <img src={infoCirclekImg} alt="" />
                  </button>
                  <div className="toltip-description">{defaultDisclaimer}</div>
                </div>
              )}

            {showFeedback && showFavBar && (
              <>
                <div className="bot-reply-feedback-container">
                  <span
                    className={`bot-reply-feedback-icon${
                      localFeedback === 1
                        ? " bot-reply-feedback-icon-selected"
                        : ""
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      recordFeedback(1, replyId);
                    }}
                  >
                    <button className="btn">
                      <img
                        src={
                          localFeedback === 1 ? thumpsUpFilled : thumbsupBlue
                        }
                        alt=""
                      />
                    </button>
                  </span>
                  <span
                    className={`bot-reply-feedback-icon${
                      localFeedback === 0
                        ? " bot-reply-feedback-icon-selected"
                        : ""
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      recordFeedback(0, replyId);
                    }}
                  >
                    <button className="btn">
                      <img
                        src={
                          localFeedback === 0
                            ? thumpsDownFilled
                            : thumbsDownBlue
                        }
                        alt=""
                      />
                    </button>
                  </span>
                </div>
              </>
            )}
          </div>
        </>
      );
    } else if (data.template === "image" && data.image) {
      return <ImagePreview src={data.image.url} altText={data.text} />;
    } else if (data.template === "image" && !data.image) {
      return <ImagePreview html={data.html} altText={data.text} />;
    } else if (data.template === "video") {
      return <VideoPreview link={data.video.url} html={data.html} />;
    } else if (data.template === "sticker") {
      return <StickerPreview src={data.image.url} altText={"sticker"} />;
    }
  };

  if (replyType === "loading") {
    return (
      <div className="loading-bubble">
        <LoadingAnimation />
      </div>
    );
  }

  return (
    <div className="bot-reply-wrapper">
      <div className="flex-container">
        <div
          className={
            element &&
            element.context &&
            element.context.botmode === "improvising"
              ? "bot-reply-container bot-reply-container-from-gpt"
              : "bot-reply-container"
          }
        >
          {data.template === "text" &&
            documentPreviewSetting &&
            documentPreviewSetting.allowed_channels &&
            documentPreviewSetting.allowed_channels.includes("widget") && (
              <div
                className="bot-document-preview-button"
                onClick={() => setDocumentPreview(data.text, replyId)}
              >
                Read More
              </div>
            )}
          {getBotReplyItem()}
          {/* <div className="more-menu-wrapper">
            <button
              className="more-menu-button"
              onClick={() => bookmarkAction("bot", data.text)}
              title="Menu"
            >
              <i className="icon-star-outline"></i>
            </button>
          </div> */}
        </div>
        {/* {showFeedback && (
          <div className="bot-reply-feedback-container">
            <i
              className={`icon-thumbs-up bot-reply-feedback-icon${
                feedback === 1 ? " bot-reply-feedback-icon-selected" : ""
              }`}
              onClick={() => recordFeedback(1, replyId)}
            />
            <i
              className={`icon-thumbs-down bot-reply-feedback-icon${
                feedback === 0 ? " bot-reply-feedback-icon-selected" : ""
              }`}
              onClick={() => recordFeedback(0, replyId)}
            />
          </div>
        )} */}
      </div>
      <div className="bot-reply-time">
        <Moment format="DD/MM/YYYY HH:mm" utc local>
          {new Date()}
        </Moment>
      </div>
    </div>
  );
};

export default BotReply;
