import React, { useState, useEffect } from "react";
import FormBuilder from "react-jsonschema-form";

import Button from "../Button";
import FileUpload from "../FileUpload";
import DatePicker from "../DatePicker";
import FormDropdown from "../FormDropdown";
import RatingInput from "../RatingInput";
import Radio from "../Radio";

import "./style.css";

const Form = ({
  onBack,
  prefilledFormData,
  primaryHeaderText,
  formSchema,
  uiSchema,
  onSubmit,
  submitButtonLabel,
  formId,
  disableButton,
  isUserDetailsForm,
  ...rest
}) => {
  const [formData, setFormData] = useState(
    isUserDetailsForm
      ? {
          name: prefilledFormData.name,
          property0: prefilledFormData.name,
          email: prefilledFormData.email,
          property1: prefilledFormData.email,
        }
      : {}
  );
  const [disableFormButton, setDisableFormButton] = useState(
    prefilledFormData ? false : true
  );

  useEffect(() => {
    if (formSchema && formSchema.required && formSchema.required.length === 0) {
      setDisableFormButton(false);
    }
  }, [formSchema]);

  const CustomDescriptionField = ({ id, description }) => {
    return (
      <div
        className="form-field-description"
        id={id}
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>
    );
  };

  const CustomTitleField = ({ title, required }) => {
    const legend = required
      ? title + <span className="mandatory-star">*</span>
      : title;
    return <div id="custom">{legend}</div>;
  };

  let widgets = {
    FileWidget: FileUpload,
    DateWidget: DatePicker,
    SelectWidget: FormDropdown,
    // CheckboxesWidget: Checkbox,
    RadioWidget: Radio,
    RatingWidget: RatingInput,
  };

  let fields = {
    DescriptionField: CustomDescriptionField,
    TitleField: CustomTitleField,
  };

  const checkDropdownData = (item) => {
    if (item instanceof Object && item.value && item.label) {
      return item.value;
    } else if (item instanceof Date) {
      let month = item.getUTCMonth() + 1; //months from 1-12
      let day = item.getUTCDate();
      let year = item.getUTCFullYear();
      return day + "/" + month + "/" + year;
    } else {
      return item;
    }
  };

  const handleOnSubmit = (formDetails) => {
    let currentData = {};
    let details = { ...formDetails };
    for (let k in formDetails.formData) {
      if (formDetails.formData[k] instanceof Array) {
        let v = [];
        formDetails.formData[k].forEach((item) =>
          v.push(checkDropdownData(item))
        );
        currentData[k] = v;
      } else {
        currentData[k] = checkDropdownData(formDetails.formData[k]);
      }
    }
    if (!disableFormButton && isUserDetailsForm) {
      onSubmit(formId, formDetails);
    } else if (Object.keys(currentData).length > 0) {
      details.formData = currentData;
      onSubmit(formId, details);
    }
  };

  const getFormValue = (value) => {
    if (value instanceof String) {
      return value;
    } else if (value instanceof Object) {
      for (let k in value) {
        value[k] = value[k].toString();
      }
      return value;
    } else if (value instanceof Date) {
      return value.toISOString();
    }

    return value ? value.toString() : value;
  };

  const handleFormChange = (data) => {
    let currentData = {};
    for (let k in data.formData) {
      if (data.formData[k] instanceof Array) {
        let v = [];
        data.formData[k].forEach((item) => v.push(getFormValue(item)));
        currentData[k] = v;
      } else {
        currentData[k] = getFormValue(data.formData[k]);
      }
    }
    setFormData(currentData);
    if (
      formSchema &&
      formSchema.required &&
      formSchema.required.length > 0 &&
      data.formData
    ) {
      formSchema.required.forEach((property) => {
        if (property in data.formData) {
          setDisableFormButton(false);
        } else {
          setDisableFormButton(true);
        }
      });
    }
  };

  return (
    <div className="form-container">
      {onBack !== undefined && (
        <button className="go-back-button" onClick={onBack}>
          <i className="icon-left-chevron back-icon" />
          Go back
        </button>
      )}
      {primaryHeaderText && (
        <div className="form-primary-header">{primaryHeaderText}</div>
      )}
      <FormBuilder
        {...rest}
        noValidate
        schema={formSchema}
        onSubmit={(formId, formDetails) => {
          handleOnSubmit(formId, formDetails, formData);
        }}
        uiSchema={uiSchema}
        widgets={widgets}
        fields={fields}
        onChange={handleFormChange}
        formData={formData}
      >
        <div className="submit-button-container">
          <Button
            buttonClass="custom-button primary-button"
            // onClick={handleOnSubmit}
            isDisabled={disableFormButton}
          >
            {submitButtonLabel}
          </Button>
        </div>
      </FormBuilder>
    </div>
  );
};

export default Form;
