import React, { useState, useEffect } from "react";
import { authHeader } from "../../helpers/auth-header";

import "./style.css";
import LoadingAnimation from "../LoadingAnimation";
import QuizReport from "../QuizReport";

const BotQuizResultBubble = ({
  submissionData,
  avatarIcon,
  onClick,
  hideScores,
  displayedQuestionsId,
  updateScrollbar,
  showLoading,
  bubbleType,
  submissionId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userScore, setUserScore] = useState(0);
  const [updatedSubmissionData, setUpdatedSubmissionData] =
    useState(submissionData);

  useEffect(() => {
    if (showLoading) {
      setIsLoading(true);

      setTimeout(() => {
        setIsLoading(false);
        updateScrollbar();
      }, 2000);
    }

    if (bubbleType === "history" && submissionId) {
      getSubmissionById(submissionId);
    } else if (submissionData.score && bubbleType !== "history") {
      setUserScore(submissionData.score);
    }
  }, []);

  const getSubmissionById = (submissionId) => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };
    if (true) {
      fetch(
        `${window.CHAT_API}/api/v1/quiz/quiz-submissions/${submissionId}`,
        requestOptions
      )
        .then((res) => {
          if (res.status.code === 200) {
            setUserScore(res.data.quiz_submission.score);
            setUpdatedSubmissionData(res.data.quiz_submission);
          }
        })
        .catch(console.log);
    }
  };

  return (
    <div className={"bot-quiz-bubble-wrapper"}>
      {isLoading && (
        <div className="bot-bubble">
          {avatarIcon && (
            <div className="bot-chat-avatar-container">
              <img className="bot-avatar-img" src={avatarIcon} alt="WA" />
            </div>
          )}
          <div className="loading-bubble">
            <LoadingAnimation />
          </div>
        </div>
      )}

      {!isLoading && (
        <div className="bot-bubble">
          {avatarIcon && (
            <div className="bot-chat-avatar-container">
              <img className="bot-avatar-img" src={avatarIcon} alt="WA" />
            </div>
          )}
          <div
            className={
              bubbleType === "history"
                ? "quiz-submission-page-frame"
                : "quiz-submission-page-frame quiz-submission-page-frame-full"
            }
          >
            <QuizReport
              // hideScores={hideScores}
              hideMainButton
              submissionData={updatedSubmissionData}
              type={"success"}
              onClick={onClick}
              displayedQuestionsId={displayedQuestionsId}
              userScore={userScore}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default BotQuizResultBubble;
