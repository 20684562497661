import React, { useRef } from "react";

import InputCard from "../InputCard";

import "./style.css";

const BulletEditor = ({
  data,
  handleCardDelete,
  onInputEdit,
  handlePaste,
  handleKeyPress,
  shouldAutoFocus,
  identifier,
}) => {
  const showInstruction = useRef(null);
  return (
    <div className="bullet-editor">
      {data.map((content, index) => {
        if (content) {
          showInstruction.current = true;
        } else {
          showInstruction.current = false;
        }
        return (
          <InputCard
            autoFocus={shouldAutoFocus}
            key={index.toString()}
            id={`input${identifier.toString()}${index.toString()}`}
            numberLabel="&#8226;"
            hideClose={index === 0 ? true : false}
            placeholder={
              index === 0 ? "Start typing here" : "Type next point here"
            }
            type="edit"
            value={content}
            onClose={() => handleCardDelete(index)}
            inputChange={(event) => onInputEdit(event, index)}
            onPrimaryPaste={(event) => handlePaste(event, index)}
            tabIndex={index + 1}
            useTextArea
            handleKeyPress={(event) => handleKeyPress(event, index)}
          />
        );
      })}
      {showInstruction.current && (
        <div className="bullet-editor-instruction">
          Press ENTER to type next point
        </div>
      )}
    </div>
  );
};

export default BulletEditor;
