import React from "react";

import "./style.css";

const StickerPreview = (props) => {
  if (props.src) {
    return (
      <div className="sticker-preview-anchor">
        <img
          className="sticker-preview-img"
          alt={props.altText ? props.altText : "Image"}
          src={props.src}
        />
      </div>
    );
  } else if (props.html) {
    return (
      <div className="sticker-preview-anchor">
        <div
          className="sticker-preview-img"
          dangerouslySetInnerHTML={{ __html: props.html }}
        />
      </div>
    );
  }
};

export default StickerPreview;
