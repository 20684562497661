import React, { Children } from "react";
import PropTypes from "prop-types";

import Button from "../Button";
import "./style.css";

const Modal = ({
  children,
  title,
  subTitle,
  buttonLabel,
  buttonAction,
  openModal,
  onRequestClose
}) => {
  if (!openModal) {
    return null;
  }

  return (
    <div className="chat-modal">
      <div className="modal-content">
        <span className="close" onClick={onRequestClose}>
          {" "}
          &times;
        </span>
        {title && (
          <div className="modal-title">
            {title}
            {subTitle && <div className="modal-subtitle">{subTitle}</div>}
          </div>
        )}
        {Children.toArray(children)}
        {buttonLabel && buttonAction && (
          <div className="modal-button-container">
            <Button
              buttonClass="custom-button primary-button"
              onClick={buttonAction}
            >
              {buttonLabel}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.object,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonAction: PropTypes.func,
  openModal: PropTypes.bool,
  onRequestClose: PropTypes.func
};

export default Modal;
