import React, { useState } from "react";
import TextInput from "../TextInput";
import Button from "../Button";

import "./style.css";

const LoginForm = ({ onLogin, handleForgotPassword }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showForgotPasswordForm, setShowForgotPasswordForm] = useState(false);

  const inputChange = (event, name) => {
    switch (name) {
      case "email":
        setEmail(event.target.value);
        break;
      case "password":
        setPassword(event.target.value);
        break;
      default:
        return;
    }
  };

  const handleKeyPress = event => {
    if (email && password && event.key === "Enter") {
      onLogin(email, password);
    }
  };

  const handleForgotPasswordKeyPress = event => {
    if (email && event.key === "Enter") {
      handleForgotPassword(email);
    }
  };

  return (
    <div className="login-form-container">
      {!showForgotPasswordForm && (
        <>
          <div className="form-heading">WELCOME BACK!</div>
          <div className="form-subheading">SIGN IN</div>
          <div className="field-container">
            <TextInput
              label={"EMAIL ID"}
              inputType="text"
              inputClass="input-nf"
              value={email}
              inputChange={inputChange}
              name="email"
              onKeyPress={handleKeyPress}
            />
          </div>
          <div className="field-container">
            <TextInput
              label={"PASSWORD"}
              inputType="password"
              inputClass="input-nf"
              value={password}
              inputChange={inputChange}
              name="password"
              onKeyPress={handleKeyPress}
            />
          </div>
          <div
            className="forgot-password-button"
            onClick={() => setShowForgotPasswordForm(true)}
          >
            Forgot Password?
          </div>
          <Button
            buttonClass="primary-button custom-button"
            onClick={() => onLogin(email, password)}
          >
            SIGN IN
          </Button>
        </>
      )}
      {showForgotPasswordForm && (
        <>
          <div className="form-subheading">FORGOT PASSWORD?</div>
          <div className="field-container">
            <TextInput
              label={"ENTER YOUR EMAIL ID TO RESET PASSWORD"}
              inputType="text"
              inputClass="input-nf"
              value={email}
              inputChange={inputChange}
              name="email"
              onKeyPress={handleForgotPasswordKeyPress}
            />
          </div>
          <Button
            buttonClass="primary-button custom-button"
            onClick={() => handleForgotPassword(email)}
          >
            SEND LINK
          </Button>
        </>
      )}
    </div>
  );
};

export default LoginForm;
