import React from "react";
// import Fingerprint2 from "fingerprintjs2";
import Helmet from "react-helmet";
import { v4 as uuidv4 } from "uuid";
import { authHeader, sleep } from "../../helpers/auth-header";
import {
  linkify,
  prettifyDate,
  getNRandomArrayIndices,
  getUrlParameter,
} from "../../utils/formatFunctions";

import TopicScroll from "../../components/TopicScroll";
import ChatInput from "../../components/ChatInput";
import BotChatBubble from "../../components/BotChatBubble";
import UserChatBubble from "../../components/UserChatBubble";
import DocumentPreview from "../../components/DocumentPreview";
import Modal from "../../components/Modal";
import TextInput from "../../components/TextInput";
import SuggestionButton from "../../components/SuggestionButton";
import Snackbar from "../../components/Snackbar";
import AuthContext from "../../context/auth";
import UserAvatarIcon from "../../assets/images/avatar1.jpg";
import AvatarIcon from "../../assets/images/walter-head-white.png";
import Form from "../../components/Form";
import FormSubmissionMessage from "../../components/FormSubmissionMessage";
import QuizGenerator from "../../components/QuizGenerator";
import QuizReport from "../../components/QuizReport";
import UserDetailsForm from "../UserDetailsForm";
import BotReply from "../../components/BotReply";
import BotQuizBubble from "../../components/BotQuizBubble";
import BotQuizResultBubble from "../../components/BotQuizResultBubble";

import "./style.css";
import { array, instanceOf } from "prop-types";
import { toIdSchema } from "react-jsonschema-form/lib/utils";
import InitialScreen from "../InitialScreen";
import DefaultPeepingFace from "../../assets/images/default-peeping-face.png";
import WalterLeftHand from "../../assets/images/walter-left-hand.png";
import WalterRightHand from "../../assets/images/walter-right-hand.png";
import WalterSideHand from "../../assets/images/walter-side-hand.png";
import { fetchEventSource } from "@microsoft/fetch-event-source";

class NewChat extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      feedbackMap: {},
      feedbackComment: "",
      feedbackId: "",
      newMessage: "",
      sessionData: [],
      openModal: false,
      suggestedQuestionsData: [],
      chatData: [],
      chatData2: [],
      count: 0,
      snackbar: {
        isOpen: false,
        message: "",
        type: "success",
      },
      selectedSuggestion: "",
      popupState: false,
      showForm: false,
      authToken: "",
      form: {},
      showFormSubmissionMessage: false,
      formSubmissionMessageDetails: {
        heading: "",
        message: "",
        buttonLabel: "",
        type: "",
      },
      launchButtonClass: "positon-right",
      widgetSettings: {
        headerName: "Walter",
        headerIcon: AvatarIcon,
        isTest: false,
        branding: true,
        homeScreenMessage: "",
        showPeepingImg: false,
        showPeepingImgHands: true,
        peepingImgPosition: "top",
        peepingImgCustom: "",
        widgetPosition: "right",
        buttonShape: "default",
      },
      showSuggestedQuestions: false,
      showQuiz: false,
      quiz: {},
      disableQuizButton: false,
      showQuizReport: {
        open: false,
        type: "success",
        data: {},
      },
      showDocumentPreview: false,
      answerForDocumentPreview: "",
      replyIdForDocumentPreview: "",
      documentPreviewContent: "",
      hideClose: false,
      enableUserDetailsForm: false,
      enableInitialScreen: false,
      botDelay: 2000,
      userDetailsFormMessage: "",
      userDetailsFormLayout: {},
      chatContext: "",
      suggestionsExpanded: false,
      hideChatInput: false,
      agentIdParameter: "",
      buttonReference: "",
      quizDisplayedQuestions: [],
      customButtonData: [],
      customBotNameLoaded: null,
      customBotName: null,
      customBotAvatar: null,
      currentConversationalQuiz: {},
      convoQuizAnswers: [],
      currentSessionId: "",
      disableCustomButton: false,
      isConversationalQuizActive: false,
      disableInput: false,
      conversationalQuizStrategy: "normal",
      prefilledFormData: {},
      tabTitle: "Chat",
      errorBanner: true,
      isLoading: false,
      defaultDisclaimer:
        "Please note that the information you receive from now on is outside of the set knowledge base and the system may occasionally generate incorrect or misleading information and produce offensive or biased content.",
    };
    this.chatDataRef = React.createRef(this.state.chatData);
    this.startListenChat = this.startListenChat.bind(this);
  }

  generateFingerprint = () => {
    let fingerprint = "";

    let options = {
      excludes: {
        webgl: true,
        audio: true,
        fonts: true,
        canvas: true,
        enumerateDevices: true,
        fontsFlash: true,
      },
    };

    // Fingerprint2.get(options, function (components) {
    //   let values = components.map(function (component) {
    //     return component.value;
    //   });
    //   fingerprint = Fingerprint2.x64hash128(values.join(""), 31);
    // });

    return fingerprint;
  };

  componentDidMount() {
    this.getWidgetSettings();
    document.addEventListener("mousedown", this.handleDocumentClick);
  }

  getAgentSettings = () => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(this.state.authToken),
    };

    let requestUrl =
      "/api/v1/organisation/agents/current" + this.state.agentIdParameter;

    fetch(window.CHAT_API + requestUrl, requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json.status.code === 200) {
          if (
            json.data.agent &&
            json.data.agent.settings &&
            "customised_buttons" in json.data.agent.settings
          ) {
            this.setState({
              customButtonData: json.data.agent.settings.customised_buttons,
            });
          }

          if (json.data.agent && json.data.agent.settings) {
            this.setState({
              customBotName: json?.data?.agent?.settings?.chat_bot_name || "",
              customBotAvatar:
                json?.data?.agent?.settings?.chat_bot_avatar || "",
              defaultDisclaimer:
                json.data.agent?.settings?.improvising_disclaimer ||
                "Please note that the information you receive from now on is outside of the set knowledge base and the system may occasionally generate incorrect or misleading information and produce offensive or biased content.",
            });
          }

          this.setState({
            ...this.state,
            customBotNameLoaded: true,
          });
        } else {
          this.setState({
            customBotNameLoaded: true,
            snackbar: {
              isOpen: true,
              message: "Oops! Something went wrong.",
              type: "error",
            },
          });
        }
      });
  };

  getAgentDisclaimer = () => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(this.state.authToken),
    };

    if (!this.state.agentIdParameter) {
      return;
    }

    let requestUrl =
      "/api/v1/organisation/agents/current" + this.state.agentIdParameter;

    fetch(window.CHAT_API + requestUrl, requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json.status.code === 200) {
          if (json.data.agent && json.data.agent.settings) {
            this.setState({
              defaultDisclaimer:
                json.data.agent?.settings?.improvising_disclaimer ||
                "Please note that the information you receive from now on is outside of the set knowledge base and the system may occasionally generate incorrect or misleading information and produce offensive or biased content.",
            });
          }
        } else {
        }
      });
  };

  getWidgetSettings = async () => {
    let widgetSettings = { ...this.state.widgetSettings };
    let allParams = {};

    let fingerprint = this.generateFingerprint();

    let draftAgentId = getUrlParameter(
      this.props.location.search.split("?")[1]
    );

    if (draftAgentId.agent_id) {
      this.setState({
        agentIdParameter: "?agent_id=" + draftAgentId.agent_id,
      });
    }

    let draftPrefilledFormData = getUrlParameter(
      this.props.location.search.split("?")[1]
    );

    let temp = {};

    if (draftPrefilledFormData.info_Name) {
      temp.name = draftPrefilledFormData.info_Name;
    }

    if (draftPrefilledFormData.info_Email) {
      temp.email = draftPrefilledFormData.info_Email;
    }

    this.setState({
      prefilledFormData: temp,
    });

    if (this.props.match.params.directChatKey) {
      this.setState({
        hideClose: true,
      });
      try {
        const response = await fetch(
          window.ADMIN_API +
            "/api/v1/widget/direct-chats/" +
            this.props.match.params.directChatKey +
            "?cid=" +
            fingerprint +
            this.state.agentIdParameter.replace("?", "&"),
          {
            method: "GET",
          }
        );
        const json = await response.json();

        if (json.status.code === 200) {
          allParams = json.data.settings;
        }
      } catch (error) {
      }
    } else {
      this.setState({
        hideClose: false,
      });
      allParams = getUrlParameter(this.props.location.search.split("?")[1]);
    }

    this.setState({
      authToken: allParams.t ? allParams.t : "",
      queryParameters: allParams,
    });

    fetch(
      window.CHAT_API + "/api/v1/widget/settings" + this.state.agentIdParameter,
      {
        method: "GET",
        headers: authHeader(allParams.t),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json.status.code === 200) {
          if (json.data.widget && json.data.widget.agent_id) {
            this.setState({
              agentIdParameter: "?agent_id=" + json.data.widget.agent_id,
            });
          }

          let settings = json.data.widget.settings;

          if (settings.home_screen) {
            widgetSettings.homeScreenMessage = settings.home_screen.message;
          }

          const root = document.querySelector(":root");

          if (settings && settings.color) {
            root.style.setProperty("--primary-color", `#${settings.color}`);
          }
          if (settings && settings.text_color) {
            root.style.setProperty(
              "--primary-text-color",
              `#${settings.text_color}`
            );
          }

          if (settings && settings.user_color) {
            root.style.setProperty("--user-color", `#${settings.user_color}`);
          }

          if (settings && settings.user_text_color) {
            root.style.setProperty(
              "--user-text-color",
              `#${settings.user_text_color}`
            );
          }

          if (settings && settings.button_color) {
            root.style.setProperty(
              "--button-color",
              `#${settings.button_color}`
            );
          }

          if (settings && settings.button_text_color) {
            root.style.setProperty(
              "--button-text-color",
              `#${settings.button_text_color}`
            );
          }

          if ("position" in settings) {
            if (settings.position === 0) {
              widgetSettings.widgetPosition = "right";
            } else if (settings.position === 1) {
              widgetSettings.widgetPosition = "left";
            }

            const launchButton =
              document.getElementsByClassName("chat-launch-button");
            if (launchButton[0] && settings.position === 0) {
              this.setState({ launchButtonClass: "position-right" });
            } else if (launchButton[0] && settings.position === 1) {
              this.setState({ launchButtonClass: "position-left" });
            }
          }

          if (settings.header_name) {
            widgetSettings.headerName = settings.header_name;
          }

          if (settings.header_icon) {
            widgetSettings.headerIcon = settings.header_icon;
          }

          if ("branding" in settings) {
            widgetSettings.branding = settings.branding;
          }

          // peeping image settings
          if ("peeping_image_enabled" in settings) {
            widgetSettings.showPeepingImg = settings.peeping_image_enabled;
          }
          if ("peeping_image_hands_enabled" in settings) {
            widgetSettings.showPeepingImgHands =
              settings.peeping_image_hands_enabled;
          }
          if ("peeping_image_position" in settings) {
            widgetSettings.peepingImgPosition = settings.peeping_image_position;
          }
          if ("peeping_image_custom_image_url" in settings) {
            widgetSettings.peepingImgCustom =
              settings.peeping_image_custom_image_url;
          }

          // button shape
          if ("button_shape" in settings) {
            widgetSettings.buttonShape = settings.button_shape;
          }

          if (
            typeof json.data.widget !== "undefined" &&
            typeof json.data.widget.information_form !== "undefined"
          ) {
            if (
              json.data.widget.information_form.is_required &&
              json.data.widget_user === null
            ) {
              this.setState({
                enableUserDetailsForm: true,
                userDetailsFormMessage:
                  json.data.widget.information_form.form.message,
                userDetailsFormLayout: JSON.parse(
                  json.data.widget.information_form.form.layout
                ),
              });
            } else {
              if (!allParams.test) {
                this.setState({
                  enableInitialScreen: true,
                });
              }
            }
          }
        } else {
          this.setState({
            snackbar: {
              isOpen: true,
              message: "Oops! Something went wrong.",
              type: "error",
            },
          });
        }
      });

    if (allParams.test && allParams.test === "1") {
      widgetSettings.isTest = true;
      this.setState({
        hideClose: true,
      });
    }

    this.setState({ widgetSettings }, () => {
      this.initialiseChat();
      this.getAgentSettings();
    });
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleDocumentClick);
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  setLastLogRef = (node) => {
    this.wrapperRef = node;
  };

  setInputRef = (node) => {
    this.inputRef = node;
  };

  getBotDelay = () => {
    fetch(
      window.CHAT_API +
        "/api/v1/organisation/organisations/current" +
        this.state.agentIdParameter,
      {
        method: "GET",
        headers: authHeader(this.state.authToken),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json.status.code === 200) {
          if (
            json.data.organisation.settings &&
            json.data.organisation.settings.webchat &&
            json.data.organisation.settings.webchat.bot_delay
          ) {
            this.setState({
              botDelay:
                json.data.organisation.settings.webchat.bot_delay * 1000,
            });
          }
          if (json.data.organisation && json.data.organisation.name) {
            this.setState({
              tabTitle: `${json.data.organisation.name} - Chat`,
            });
          }
        } else {
          this.setState({
            snackbar: {
              isOpen: true,
              message: "Oops! Something went wrong.",
              type: "error",
            },
          });
        }
      });
  };

  handleDocumentClick = (event) => {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      !this.inputRef.contains(event.target)
    ) {
      this.setState({
        showSuggestedQuestions: false,
        suggestionsExpanded: false,
      });
    }
    if (this.inputRef && this.inputRef.contains(event.target)) {
      if (!this.state.suggestionsExpanded) {
        this.setState({
          showSuggestedQuestions: true,
          suggestionsExpanded: true,
        });
      }
    }
  };

  setContrast = (hexcolor) => {
    // If a leading # is provided, remove it
    if (hexcolor.slice(0, 1) === "#") {
      hexcolor = hexcolor.slice(1);
    }
    // If a three-character hexcode, make six-character
    if (hexcolor.length === 3) {
      hexcolor = hexcolor
        .split("")
        .map(function (hex) {
          return hex + hex;
        })
        .join("");
    }
    let r = parseInt(hexcolor.substr(0, 2), 16);
    let g = parseInt(hexcolor.substr(2, 2), 16);
    let b = parseInt(hexcolor.substr(4, 2), 16);

    let yiq = (r * 299 + g * 587 + b * 114) / 1000;

    if (yiq >= 128) {
      const root = document.querySelector(":root");
      root.style.setProperty("--primary-text-color", "#07032b");
    } else {
      const root = document.querySelector(":root");
      root.style.setProperty("--primary-text-color", "#fff");
    }
  };

  initialiseChat = () => {
    this.getBotDelay();

    if (!this.state.showForm) {
      // this.getGreetings();

      if (this.state.widgetSettings.isTest) {
        this.getGreetings();
      }
      this.fetchSession();
      this.getSuggestedQuestions();
    }
  };

  closePopup = () => {
    this.setState({ popupState: false, chatData: [] });
  };

  startNewConversation = () => {
    this.setState({ enableInitialScreen: false, chatData: [] });
    this.getGreetings();
  };

  getGreetings = async () => {
    await this.startListenChat({
      method: "POST",
      headers: authHeader(this.state.authToken),
      body: {
        is_greeting: true,
        source: "widget",
        test: this.state.widgetSettings.isTest,
      },
    });
  };

  addHtmlComponent = (component) => {
    if (
      !component?.props?.replyId &&
      typeof component.props?.message !== "string"
    ) {
      return {
        ...component,
        props: {
          ...component.props,
          message: component.props?.message?.map((btn) => ({
            ...btn,
            props: {
              ...btn.props,
              disabled: this.state.hideChatInput || this.state.disableInput,
            },
          })),
          // message: {
          //   ...component.props.message,
          //   props: {
          //     ...component.props?.message?.props,
          //     disabled: this.state.hideChatInput || this.state.disableInput
          //   }
          // }
        },
      };
    }

    return {
      ...component,
      props: {
        ...component.props,
        html: component?.props?.data?.html,
        feedback: this.state.feedbackMap[component.props.replyId],
      },
    };
  };

  generateContent = (keyword, preceed, succeed) => {
    fetch(
      window.CHAT_API +
        "/api/v1/convos/replies/" +
        this.state.replyIdForDocumentPreview +
        "/content",
      {
        method: "POST",
        headers: authHeader(this.state.authToken),
        body: JSON.stringify({
          keyword: keyword,
          preceed: parseInt(preceed),
          succeed: parseInt(succeed),
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json.status.code === 200) {
          this.setState({
            documentPreviewContent: json.data.content,
          });
        } else {
          if (json.status && json.status.message) {
            this.setState({
              snackbar: {
                isOpen: true,
                message: "Oops! " + json.status.message,
                type: "error",
              },
            });
          }
        }
      });
  };

  getSuggestedQuestions = () => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(this.state.authToken),
    };

    let requestUrl =
      "/api/v1/chatbot/suggested-questions" + this.state.agentIdParameter;

    if (this.state.chatContext) {
      requestUrl = requestUrl + "&topic_ids=" + this.state.chatContext;
    }

    fetch(window.CHAT_API + requestUrl, requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json.status.code === 200) {
          this.setState({
            suggestedQuestionsData: json.data.suggested_questions,
          });
        } else {
          this.setState({
            snackbar: {
              isOpen: true,
              message: "Oops! Something went wrong.",
              type: "error",
            },
          });
        }
      });
  };

  postFeedback = (feedback, key) => {
    const requestOptions = {
      method: "POST",
      headers: authHeader(this.state.authToken),
      body: JSON.stringify({ type: feedback }),
    };
    fetch(
      window.CHAT_API +
        "/api/v1/chatbot/replies/" +
        key +
        "/feedback" +
        this.state.agentIdParameter,
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json.status.code === 200) {
          this.setState({
            snackbar: {
              isOpen: true,
              message: "Thank you for your feedback.",
              type: "success",
            },
          });
        } else {
          this.setState({
            snackbar: {
              isOpen: true,
              message: "Oops! Something went wrong.",
              type: "error",
            },
          });
        }
      });
  };

  recordFeedback = (feedback, key) => {
    let feedbacks = { ...this.state.feedbackMap };
    if (feedbacks[key] === feedback) {
      return;
    }
    feedbacks[key] = feedback;
    this.setState({
      feedbackId: key,
      feedbackMap: feedbacks,
    });
    if (feedback === 0) {
      this.setState({
        openModal: true,
      });
    } else {
      this.postFeedback(feedback, key);
    }
  };

  sendFeedbackComment = () => {
    if (this.state.feedbackComment) {
      const requestOptions = {
        method: "POST",
        headers: authHeader(this.state.authToken),
        body: JSON.stringify({ type: 0, comment: this.state.feedbackComment }),
      };

      fetch(
        window.CHAT_API +
          "/api/v1/chatbot/replies/" +
          this.state.feedbackId +
          "/feedback" +
          this.state.agentIdParameter,
        requestOptions
      )
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          if (json.status.code === 200) {
            this.setState({
              snackbar: {
                isOpen: true,
                message: "Thank you for your feedback.",
                type: "success",
              },
            });
          } else {
            this.setState({
              snackbar: {
                isOpen: true,
                message: "Oops! Something went wrong.",
                type: "error",
              },
            });
          }
        });
    }
    this.setState({
      feedbackComment: "",
      openModal: false,
    });
  };

  onCustomButtonClick = (button) => {
    const buttonsId = button?.buttonsId;
    this.setState({
      disableCustomButton: true,
      suggestionsExpanded: false,
    });

    let type = "",
      referenceId = "";

    if (button.reference) {
      type = button.reference.split("::")[0];
      referenceId = button.reference ? button.reference.split("::")[1] : "";
    }

    if (button.reference_id) {
      referenceId = button.reference_id;
    }
    type = button.type ? button.type : type;
    if (
      type === "searchoutside" ||
      type === "tellmemore" ||
      type === "expandonthat" ||
      type === "question"
    ) {
      this.onChatButtonClick({
        question: button.label,
        reference: button.reference,
        type: "question",
        buttonsId: buttonsId,
      });
      return;
    }

    fetch(
      window.CHAT_API +
        `/api/v1/chatbot/button-data/${type}::${referenceId}` +
        this.state.agentIdParameter,
      {
        method: "GET",
        headers: authHeader(this.state.authToken),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json.status.code === 200) {
          let temp = {};
          if (json.data.button_data?.form) {
            temp = {
              form: json.data.button_data.form,
              type: "form",
            };
          } else if (json.data.button_data?.quiz) {
            temp = {
              quiz: json.data.button_data.quiz,
              type: "quiz",
            };
          } else {
            let queText = json.data?.button_data?.question;
            if (!queText) {
              const typeIndex = button?.label.indexOf(":");
              queText = button?.label.slice(typeIndex + 1);
            }

            temp = {
              question: queText,
              reference: button.reference,
              type: "question",
            };
          }

          this.onChatButtonClick({ ...temp, buttonsId: buttonsId });
        }
      });
  };

  onChatButtonClick = (button) => {
    // const newChat = this.state.chatData.pop
    this.setState({
      suggestionsExpanded: false,
      disableCustomButton: false,
    });
    switch (button.type) {
      case "form":
        if (typeof button.form.layout === "string") {
          button.form.layout = JSON.parse(button.form.layout);
        }
        this.openFormScreen(button.form);
        break;
      case "quiz":
        this.setState({
          disableQuizButton: false,
        });
        this.openQuizGeneratorScreen(button.quiz);
        break;
      case "searchoutside":
      case "tellmemore":
      case "expandonthat":
      case "question":
        const newChats = this.state.chatData.filter(
          (m) => m.key !== button?.buttonsId
        );
        this.setState(
          {
            buttonReference: button?.reference
              ? button?.reference
              : this.state.buttonReference,
            chatData: newChats,
          },
          () => this.sendMessage(button.question, button.reference)
        );

        break;
      case "intent":
        if (button.reference) {
          this.setState({ buttonReference: button.reference });
        }
        this.sendMessage(button.question, button.reference);
        break;
      default:
        break;
    }
  };

  setDocumentPreview = (answer, replyId) => {
    this.setState({
      showDocumentPreview: true,
      answerForDocumentPreview: answer,
      replyIdForDocumentPreview: replyId,
    });
  };

  handleQuizReportAction = (data, origin) => {
    this.setState(
      {
        showQuizReport: {
          open: false,
          type: "success",
          data: {},
        },
        showQuiz: false,
      },
      () => {
        if (origin !== "conversational") {
          // this.fetchChat("", this.state.currentSessionId);
        }
        // this.updateScrollbar();
      }
    );

    if (data) {
      this.onCustomButtonClick(data);
    }
  };

  openFormScreen = (form) => {
    this.setState({
      form: form,
      showForm: true,
    });
  };

  handleFormBack = () => {
    this.setState({ showForm: false, chatData: [] }, () => {
      this.fetchChat("", this.state.currentSessionId);
      // this.updateScrollbar();
    });
  };

  handleFormSubmit = async (formId, formDetails) => {
    if (formDetails.formData && Object.keys(formDetails.formData).length > 0) {
      let res = await fetch(
        window.CHAT_API +
          "/api/v1/form/forms/" +
          formId +
          "/submissions" +
          this.state.agentIdParameter,
        {
          method: "POST",
          headers: authHeader(this.state.authToken),
          body: JSON.stringify({ form_data: formDetails.formData }),
        }
      );
      let json = await res.json();
      if (json.status.code === 200) {
        let details = {
          message:
            json.data.form_reply?.text.length >= 0
              ? json.data.form_reply.text
              : json.data.form_reply.error
              ? json.data.form_reply.error
              : "Looks like something is not right here. Please contact your admin.",
          heading:
            json.data.form_reply?.text.length >= 0
              ? ""
              : json.data.form_reply.error
              ? "Oops! Submission was unsuccessful."
              : "Oops! Something went wrong.",
          buttonLabel: "GO BACK TO CHAT",
          type:
            json.data.form_reply?.text.length >= 0
              ? "success"
              : json.data.form_reply.error
              ? "error"
              : "error",
        };
        this.setState({
          showFormSubmissionMessage: true,
          formSubmissionMessageDetails: details,
        });
      } else {
        this.setState({
          snackbar: {
            isOpen: true,
            message: "Oops! Something went wrong.",
            type: "error",
          },
        });
      }
    }
  };

  handleSubmissionMessageAction = () => {
    this.setState(
      {
        showFormSubmissionMessage: false,
        showForm: false,
        chatData: [],
      },
      () => {
        this.fetchChat("", this.state.currentSessionId);
      }
    );
  };

  onAnswerInput = (value, type, questionId) => {
    let {
      convoQuizAnswers,
      currentConversationalQuiz,
      conversationalQuizStrategy,
    } = this.state;
    let tempAnswers = { ...convoQuizAnswers };

    if (type === "text") {
      tempAnswers[questionId] = {
        ...convoQuizAnswers[questionId],
        text: value,
      };
      this.setState({
        convoQuizAnswers: tempAnswers,
      });
    } else if (type === "radio" || type === "checkbox") {
      tempAnswers[questionId] = {
        ...convoQuizAnswers[questionId],
        options: value,
      };
      this.setState({
        convoQuizAnswers: tempAnswers,
      });
    } else if (type === "matching") {
      let temp = [];
      Object.keys(value).forEach((key) => {
        temp.push({
          term: key,
          definition: value[key] ? value[key].id : "",
        });
      });
      tempAnswers[questionId] = {
        ...convoQuizAnswers[questionId],
        matches: temp,
      };
      this.setState({
        convoQuizAnswers: tempAnswers,
      });
    }

    let currentIdIndex = Object.keys(
      currentConversationalQuiz.transformedQuestions
    ).indexOf(questionId);

    if (
      conversationalQuizStrategy === "branching" &&
      currentConversationalQuiz.transformedQuestions[questionId].type ===
        "radio"
    ) {
      currentConversationalQuiz.transformedQuestions[questionId].options &&
        currentConversationalQuiz.transformedQuestions[
          questionId
        ].options.forEach((option) => {
          if (option.id === tempAnswers[questionId].options[0]) {
            if (option.branch && option.branch.action) {
              if (option.branch.action === "end-quiz") {
                this.askConversationalQuizQuestion(questionId, true);
              } else if (
                option.branch.action === "jump-to-question" &&
                option.branch.reference_id
              ) {
                this.askConversationalQuizQuestion(
                  option.branch.reference_id,
                  false
                );
              }
            } else if (
              currentIdIndex + 1 <=
                Object.keys(currentConversationalQuiz.transformedQuestions)
                  .length -
                  1 &&
              currentConversationalQuiz.transformedQuestions
            ) {
              this.askConversationalQuizQuestion(
                Object.keys(currentConversationalQuiz.transformedQuestions)[
                  currentIdIndex + 1
                ],
                false
              );
            } else if (
              currentIdIndex + 1 ===
              Object.keys(currentConversationalQuiz.transformedQuestions).length
            ) {
              this.askConversationalQuizQuestion(questionId, true);
            }
          }
        });
    } else if (
      currentIdIndex + 1 <=
        Object.keys(currentConversationalQuiz.transformedQuestions).length -
          1 &&
      currentConversationalQuiz.transformedQuestions
    ) {
      this.askConversationalQuizQuestion(
        Object.keys(currentConversationalQuiz.transformedQuestions)[
          currentIdIndex + 1
        ],
        false
      );
    } else if (
      currentIdIndex + 1 ===
      Object.keys(currentConversationalQuiz.transformedQuestions).length
    ) {
      this.askConversationalQuizQuestion(questionId, true);
    }
  };

  onConversationalQuizSubmit = () => {
    const selectedQuestionIds = [];
    const answersList = [];

    Object.entries(this.state.convoQuizAnswers).forEach(([key, value]) => {
      selectedQuestionIds.push(key);
      answersList.push(value);
    });

    let { currentConversationalQuiz } = this.state;
    fetch(
      window.CHAT_API +
        "/api/v1/quiz/quizzes/" +
        currentConversationalQuiz._id +
        "/submissions" +
        this.state.agentIdParameter,
      {
        method: "POST",
        headers: authHeader(this.state.authToken),
        body: JSON.stringify({
          selected_question_ids: selectedQuestionIds,
          answers: answersList,
          action: "submit",
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        let data = [...this.state.chatData];
        if (json.status.code === 200) {
          setTimeout(() => {
            data.pop();
            this.setState({ chatData: data }, () => this.updateScrollbar());

            let temp = [...data];

            this.setState(
              {
                chatData: temp.concat(
                  <BotQuizResultBubble
                    showLoading
                    // avatarIcon={botAvatar}
                    submissionData={json.data.quiz_submission}
                    displayedQuestionsId={
                      currentConversationalQuiz.displayedQuestionsId
                    }
                    onClick={this.handleQuizReportAction}
                    updateScrollbar={this.updateScrollbar}
                  />
                ),
                hideChatInput: false,
                isConversationalQuizActive: false,
              },
              () => this.updateScrollbar()
            );
          }, 2000);
        } else if (json.status.code < 500) {
          this.setState({
            snackbar: {
              isOpen: true,
              message: "Unable to submit quiz: " + json.status.message,
              type: "error",
            },
          });
        }
      });
  };

  askConversationalQuizQuestion = (currentQuestionId, isLastQuestion) => {
    let { currentConversationalQuiz } = this.state;
    let data = [...this.state.chatData];
    this.setState({
      chatData: data.concat(
        <BotQuizBubble
          // avatarIcon={botAvatar}
          questionData={
            currentConversationalQuiz.transformedQuestions &&
            currentConversationalQuiz.transformedQuestions[currentQuestionId]
          }
          questionId={currentQuestionId}
          isLastQuestion={isLastQuestion}
          onAnswerInput={this.onAnswerInput}
          onSubmit={this.onConversationalQuizSubmit}
          updateScrollbar={this.updateScrollbar}
        />
      ),
    });
    this.updateScrollbar();
  };

  findAndSetNextBranchedQuestion = (questionId) => {
    // find the index of the question which is linked to the option and set the current index
    let newCurrentIndex = null;
    this.state.currentConversationalQuiz.questions.forEach(
      (question, index) => {
        if (questionId === question.id) {
          newCurrentIndex = index;
        }
      }
    );
    this.askConversationalQuizQuestion(newCurrentIndex, false);
  };

  openQuizGeneratorScreen = (selectedQuiz) => {
    if (selectedQuiz.display_mode === "conversational") {
      let data = [...this.state.chatData];

      this.setState({
        chatData: data.slice(0, data.length - 1),
        currentConversationalQuiz: selectedQuiz,
      });
      this.updateScrollbar();

      let temp = {};
      selectedQuiz.questions.forEach((question) => {
        if (
          question.type === "text" ||
          question.type === "essay" ||
          question.type === "bullet" ||
          question.type === "newbullet"
        ) {
          temp[question.id] = {
            text: "",
            question_id: question.id,
          };
        } else if (
          question.type === "table" ||
          question.type === "description"
        ) {
          temp[question.id] = {
            text: "[]",
            question_id: question.id,
          };
        } else if (question.type === "matching") {
          temp[question.id] = {
            matches: [],
            question_id: question.id,
          };
        } else if (question.options) {
          temp[question.id] = {
            options: [],
            question_id: question.id,
          };
        }
      });
      this.setState({
        convoQuizAnswers: temp,
      });
      this.transformQuizQuestions();
    } else {
      this.setState({
        quiz: selectedQuiz,
        showQuiz: true,
      });
    }
  };

  transformQuizQuestions = () => {
    let temp = {};
    let currentQuiz = { ...this.state.currentConversationalQuiz };
    currentQuiz.questions.forEach((question) => {
      if (question.parent_id && temp[question.parent_id]) {
        temp[question.parent_id] = {
          ...temp[question.parent_id],
          subquestions:
            "subquestions" in temp[question.parent_id]
              ? temp[question.parent_id].subquestions.concat([question])
              : [].concat([question]),
        };
      } else {
        temp[question.id] = question;
      }
    });
    currentQuiz.transformedQuestions = temp;
    this.setState({ currentConversationalQuiz: currentQuiz });
    this.initialiseConversationalQuiz(currentQuiz);
  };

  initialiseConversationalQuiz = (currentQuiz) => {
    let tempStrategy = "normal";
    let displayCount = currentQuiz.display_questions_count
      ? currentQuiz.display_questions_count
      : currentQuiz.transformedQuestions.length;

    if (
      displayCount &&
      displayCount < currentQuiz.transformedQuestions.length
    ) {
      tempStrategy = "random";
      this.setState({
        conversationalQuizStrategy: "random",
      });
    }

    Object.keys(currentQuiz.transformedQuestions).forEach((questionId) => {
      if (currentQuiz.transformedQuestions[questionId].options) {
        currentQuiz.transformedQuestions[questionId].options.forEach(
          (option) => {
            if (option.branch && option.branch.action) {
              tempStrategy = "branching";
              this.setState({
                conversationalQuizStrategy: "branching",
              });
            }
          }
        );
      }
    });

    if (tempStrategy === "random") {
      let toDisplay = getNRandomArrayIndices(
        Object.keys(currentQuiz.transformedQuestions),
        displayCount
      );
      let result = Object.keys(currentQuiz.transformedQuestions);
      let temp = toDisplay.map((item) => result[item]);
      let tempQuiz = { ...currentQuiz };
      tempQuiz.displayedQuestionsId = temp;
      this.setState(
        {
          currentConversationalQuiz: tempQuiz,
          hideChatInput: true,
          isConversationalQuizActive: true,
        },
        () => this.askConversationalQuizQuestion(temp[0], false)
      );
    } else {
      let tempQuiz = { ...currentQuiz };
      tempQuiz.displayedQuestionsId = Object.keys(
        currentQuiz.transformedQuestions
      );
      this.setState(
        {
          currentConversationalQuiz: tempQuiz,
          hideChatInput: true,
          isConversationalQuizActive: true,
        },
        () =>
          this.askConversationalQuizQuestion(
            Object.keys(currentQuiz.transformedQuestions)[0],
            false
          )
      );
    }
  };

  handleQuizQuit = (quizId, data, displayedQuestionsId) => {
    this.setState({
      quizDisplayedQuestions: displayedQuestionsId,
    });
    if (data) {
      this.quizSubmissionPost(quizId, data, "quit", displayedQuestionsId);
    }
  };

  handleQuizSubmit = (quizId, data, displayedQuestionsId) => {
    this.setState({
      disableQuizButton: true,
      quizDisplayedQuestions: displayedQuestionsId,
    });
    if (data) {
      this.quizSubmissionPost(quizId, data, "submit", displayedQuestionsId);
    }
  };

  quizSubmissionPost = async (
    quizId,
    data,
    actionType,
    displayedQuestionsId
  ) => {
    let res = await fetch(
      window.CHAT_API +
        "/api/v1/quiz/quizzes/" +
        quizId +
        "/submissions" +
        this.state.agentIdParameter,
      {
        method: "POST",
        headers: authHeader(this.state.authToken),
        body: JSON.stringify({
          selected_question_ids: displayedQuestionsId,
          answers: data,
          action: actionType.toLowerCase(),
        }),
      }
    );

    let json = await res.json();

    if (json.status.code === 200) {
      // successfull
      if (actionType === "submit") {
        this.setState({
          showQuizReport: {
            open: true,
            type: "success",
            data: json.data.quiz_submission,
          },
        });
        return json.status.quiz_submission;
      } else if (actionType === "quit") {
        this.setState({
          showQuizReport: {
            open: true,
            type: "quit",
            data: json.data.quiz_submission,
          },
        });
        return json.status.quiz_submission;
      }
    } else if (json.status.code < 500) {
      this.setState({
        snackbar: {
          isOpen: true,
          message: "Unable to submit quiz: " + json.status.message,
          type: "error",
        },
      });
    } else {
      // error
      this.setState({
        snackbar: {
          isOpen: true,
          message: "Oops! Something went wrong.",
          type: "error",
        },
      });
    }
  };

  fetchSession = () => {
    fetch(
      window.CHAT_API +
        "/api/v1/chatlog/sessions?page=1&page_size=5" +
        this.state.agentIdParameter.replace("?", "&"),
      {
        method: "GET",
        headers: authHeader(this.state.authToken),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json.status.code === 200) {
          this.setState({ sessionData: json.data.sessions });
        } else {
          this.setState({
            snackbar: {
              isOpen: true,
              message: "Oops! Something went wrong.",
              type: "error",
            },
          });
        }
      });
  };

  fetchChat = (logId, sessionId) => {
    var url;
    let { feedbackMap } = this.state;

    // if (!lastLog.current) {
    // url = "/api/v1/chatbot/logs?last_log_id=" + logId + "&session_id=" + sessionId;
    url =
      "/api/v1/chatlog/logs" +
      "?session_id=" +
      sessionId +
      "&page_size=1000" +
      this.state.agentIdParameter.replace("?", "&");
    // } else {
    //   url = "/api/v1/chatbot/logs?last_log_id=" + lastLog.current;
    // }

    this.setState({
      snackbar: {
        isOpen: true,
        message: "Loading conversations..",
        type: "bubble",
      },
    });

    fetch(window.CHAT_API + url, {
      method: "GET",
      headers: authHeader(this.state.authToken),
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json.status.code === 200) {
          if (json.data.logs.length > 0 && json.data.logs[0].context) {
            if ("is_input_disabled" in json.data.logs[0].context) {
              this.setState({
                hideChatInput: json.data.logs[0].context.is_input_disabled,
              });
            }
          }

          json.data.logs.forEach((element) => {
            let { chatData } = this.state;
            let temp = [...chatData];

            if (
              element.reply.object_data &&
              Object.keys(element.reply.object_data).length > 0
            ) {
              temp.unshift(
                <BotQuizResultBubble
                  // avatarIcon={botAvatar}
                  submissionData={element.reply.object_data}
                  // displayedQuestionsId={currentQuiz.current.questions}
                  onClick={this.handleQuizReportAction}
                  bubbleType="history"
                />
              );
            } else if (
              element.reply &&
              element.reply.buttons &&
              element.reply.buttons.length
            ) {
              const buttonsId = uuidv4();
              temp.unshift(
                <UserChatBubble
                  isButtonContainer
                  key={buttonsId}
                  avatarIcon={AvatarIcon}
                  // messageId={newData.reply._id}
                  message={element.reply.buttons.map((button, index) => (
                    <SuggestionButton
                      key={index}
                      buttonLabel={button.label}
                      buttonAction={() =>
                        this.onCustomButtonClick({
                          ...button,
                          buttonsId: buttonsId,
                        })
                      }
                    />
                  ))}
                  showFeedback={false}
                />
              );
            }

            if (element.reply && element.reply.bubbles) {
              element.reply.bubbles.reverse().forEach((bubble, index) => {
                temp.unshift(
                  <BotReply
                    key={bubble.id}
                    replyId={element._id}
                    replyType={bubble.template}
                    data={bubble}
                    replyTime={prettifyDate(new Date())}
                    recordFeedback={this.recordFeedback}
                    showFeedback={true}
                    feedback={feedbackMap[element._id] || null}
                    setDocumentPreview={this.setDocumentPreview}
                    element={element}
                    defaultDisclaimer={this.state.defaultDisclaimer}
                  />
                );
              });
            } else if (element.reply) {
              temp.unshift(
                <BotChatBubble
                  // key={`${count}${replyBubbles[j]}${allData.length}`}
                  avatarIcon={AvatarIcon}
                  messageId={element._id}
                  message={linkify(element.reply.text)}
                  // messageTime={prettifyDate(new Date())}
                  feedback={feedbackMap[element._id] || null}
                  showFeedback={true}
                  recordFeedback={this.recordFeedback}
                />
              );
            }

            if (element.question && element.question.question) {
              temp.unshift(
                <UserChatBubble
                  // key={`${count}${newMessage}`}
                  avatarIcon={UserAvatarIcon}
                  message={element.question.question}
                  messageTime={prettifyDate(new Date())}
                />
              );
            }

            this.setState({
              chatData: temp,
            });

            this.updateScrollbar();
          });

          if (json.data.logs.length > 0 && json.data.logs[0].reply) {
            if (
              json.data.logs[0].reply.buttons &&
              json.data.logs[0].reply.buttons.length > 0
            ) {
              let { chatData } = this.state;
              let temp = [...chatData];
              const buttonsId = uuidv4();
              temp.unshift(
                <UserChatBubble
                  isButtonContainer
                  key={buttonsId}
                  // key={`${this.state.count}${json.data.logs[0].reply.buttons[0].label}`}
                  avatarIcon={AvatarIcon}
                  // messageId={json.data.logs[0].reply._id}
                  message={json.data.logs[0].reply.buttons.map(
                    (button, index) => (
                      <SuggestionButton
                        key={index}
                        buttonLabel={button.label}
                        buttonAction={() =>
                          this.onCustomButtonClick({
                            ...button,
                            buttonsId: buttonsId,
                          })
                        }
                      />
                    )
                  )}
                  showFeedback={false}
                />
              );

              this.setState({
                chatData: temp,
              });

              this.updateScrollbar();
            }
          }

          // if (initialLoad === true && json.data.logs.length === 0) {
          // this.getGreetings();
          // } else {
          //   this.updateScrollbar();
          // }
        } else {
          this.setState({
            snackbar: {
              isOpen: true,
              message: "Oops! Something went wrong.",
              type: "error",
            },
          });
        }
      });

    this.setState({ chatContext: "" });
    this.getSuggestedQuestions();
  };

  createBotReply = async (newData, allData, showFeedback) => {
    let documentPreviewSetting;
    let { count, feedbackMap } = this.state;
    if (newData && newData.reply && newData.reply.document_id) {
      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };

      let res = await fetch(
        window.CHAT_API +
          "/api/v1/convos/documents/" +
          newData.reply.document_id +
          "/settings" +
          this.state.agentIdParameter,
        requestOptions
      );

      let json = await res.json();

      if (json.status.code === 200) {
        documentPreviewSetting = json.data.document.settings;
      }
    }

    const replyBubbles = newData.reply.bubbles;
    if (replyBubbles && replyBubbles.length > 0) {
      for (let j = 0; j < replyBubbles.length; j++) {
        await sleep(replyBubbles[j].delay || 0);
        const newChats = [...this.state.chatData].concat(
          <BotReply
            bubbleIndex={j}
            key={replyBubbles[j].id}
            documentId={newData.reply.document_id}
            replyId={replyBubbles[j].reply_id}
            replyType={replyBubbles[j].template}
            data={replyBubbles[j]}
            replyTime={prettifyDate(new Date())}
            showFeedback={true}
            feedback={feedbackMap[replyBubbles[j].reply_id] || null}
            documentPreviewSetting={documentPreviewSetting}
            setDocumentPreview={this.setDocumentPreview}
            element={{
              ...newData,
              context: { botmode: replyBubbles[j]?.botmode },
            }}
            showFallback={replyBubbles[j]?.botmode === "improvising"}
            defaultDisclaimer={this.state.defaultDisclaimer}
            recordFeedback={this.recordFeedback}
          />
        );
        this.setState(
          () => ({ chatData: newChats }),
          () => this.updateScrollbar()
        );
      }
    }

    if (
      newData.reply &&
      newData.reply.buttons &&
      newData.reply.buttons.length
    ) {
      await sleep(newData.reply.buttons[0].delay || 0);
      const buttonsId = uuidv4();
      const newChats = [...this.state.chatData].concat(
        <UserChatBubble
          isButtonContainer
          // key={newData.reply.buttons[0].label}
          key={buttonsId}
          message={newData.reply.buttons.map((button, index) => (
            <SuggestionButton
              key={index}
              buttonLabel={button.label}
              buttonAction={() =>
                this.onCustomButtonClick({ ...button, buttonsId: buttonsId })
              }
            />
          ))}
          showFeedback={false}
        />
      );
      this.setState(
        (state) => ({ chatData: newChats }),
        () => this.updateScrollbar()
      );
      this.setState({ isLoading: false });
    }
  };

  onFieldChange = (value, label) => {
    if (label === "feedbackComment") {
      this.setState({
        feedbackComment: value,
      });
    } else if (label === "newMessage") {
      this.setState({
        newMessage: value,
      });
    }
  };

  startListenChat = async (requestOptions) => {
    const createReply = this.createReply;
    const replaceBubble = this.replaceBubble;
    const setState = this.setState.bind(this);
    const updateScrollbar = this.updateScrollbar.bind(this);
    const payload = requestOptions.body;
    setState({ isLoading: true, disableInput: true });
    this.updateScrollbar();

    await fetchEventSource(
      window.CHAT_API +
        `/api/v1/chatbot/sse/questions?agent_id=${this.state.agentIdParameter}`,
      {
        method: requestOptions.method,
        openWhenHidden: true,
        headers: requestOptions.headers,
        body: JSON.stringify(payload),
        async onopen(response) {
          if (!response.ok) {
            throw response;
          }
          // setIsLoading(true);
          // setIsSseClose(false);
        },
        async onmessage(ev) {
          const data = JSON.parse(ev.data);
          ev.data = JSON.parse(ev.data);
          // await sleep(3000);
          const delay =
            data.data?.bubbles?.[0]?.delay ||
            data.data?.replies?.[0].bubbles?.[0]?.delay ||
            data.data?.bubble?.delay ||
            data.data?.buttons?.[0]?.delay ||
            0;
          // setState({ isLoading: true, disableInput: true});
          updateScrollbar();

          if (ev.event !== "UPDATE_BUBBLE") {
            // setIsLoading(true);
          }
          switch (ev.event) {
            case "CREATE_REPLIES":
              createReply({
                context: data.data.context,
                reply: data.data.replies[0],
              });
              break;

            case "CREATE_BUBBLES":
              createReply({
                context: data.data.context,
                reply: {
                  bubbles: data.data.bubbles,
                },
              });
              // this.setState({
              //   chatData2: [...chatData2, ]
              // })
              break;
            case "CREATE_BUTTONS":
              createReply({
                context: data.data.context,
                reply: {
                  buttons: data.data.buttons,
                },
              });
              break;
            case "APPEND_BUBBLE":
              if (data?.data?.bubble) {
                replaceBubble({
                  ...data.data.bubble,
                  eventType: "APPEND_BUBBLE",
                });
              }

              break;
            case "UPDATE_BUBBLE":
              setState({ isLoading: false });
              if (data?.data?.bubble) {
                replaceBubble({
                  ...data.data.bubble,
                  eventType: "UPDATE_BUBBLE",
                });
              }
              break;
            case "UPDATE_CONTEXT":
              await (data?.data.delay || 0);
              // updateContext(data.data);
              break;
            case "ERROR":
              setState({ isLoading: false });
              setState({
                snackbar: {
                  isOpen: true,
                  message:
                    "This button/question is encountering an error. Please contact the class administrator.",
                  type: "error",
                },
              });
            default:
              break;
          }
        },
        onclose() {
          // setState({ disableInput: false, isLoading: false });
          setState({ isLoading: false, disableInput: false });
          // setIsSseClose(true);
        },
        onerror(err) {
          setState({ isLoading: false, disableInput: false });
          setState({ isLoading: false });
          if (err) {
            // setIsLoading(true);
            // setIsSseClose(false);
            // setSnackbar({
            //   isOpen: true,
            //   message: "Oops! Something went wrong.",
            //   type: "error",
            // });
          }
          // setIsLoading(false);
        },
      }
    );

    // }
  };

  sendMessage = async (newMessage, buttonReference) => {
    this.setState({
      suggestionsExpanded: false,
    });
    if (newMessage.trim()) {
      let { chatData, count } = this.state;
      let conversation = [...chatData];
      conversation.push(
        <UserChatBubble
          key={`${count}${newMessage}`}
          avatarIcon={UserAvatarIcon}
          message={newMessage}
          messageTime={prettifyDate(new Date())}
        />
      );
      this.setState({ chatData: conversation, newMessage: "" }, () =>
        this.updateScrollbar()
      );

      let payload = {
        question: newMessage,
        test: this.state.widgetSettings.isTest,
        source: "widget",
      };
      if (buttonReference) {
        payload.button_reference = buttonReference;
      }

      await this.startListenChat({
        method: "POST",
        headers: authHeader(this.state.authToken),
        body: payload,
      });

      this.setState(
        {
          newMessage: "",
          buttonReference: "",
        },
        () => {
          this.updateScrollbar();
        }
      );
    }
  };

  createReply = async (data) => {
    let conversation = [...this.state.chatData];
    // if()
    // if (data?.context?.botmode) {
    //   setBotMode(data?.context?.botmode);
    // }
    await this.createBotReply(
      data,
      conversation,
      false,
      data && data.context && data.context.botmode === "improvising"
    );
    this.updateScrollbar();

    // updateContext(data);
  };

  replaceBubble = async (b) => {
    await sleep(b.delay | 0);
    // const newChats = [...chatDataRef.current];
    const newChats = [...this.state.chatData];
    const index = newChats.findIndex((c) => c.key === b.id);
    if (index >= 0) {
      let replaceBubble;
      if (b.eventType === "APPEND_BUBBLE") {
        replaceBubble = { ...newChats[index] };
        replaceBubble.props.data.html = `<span>${replaceBubble.props.data.html}${b.html}</span>`;
        replaceBubble.props.data.text = replaceBubble.props.data.text + b.text;
        replaceBubble.props.data.document_preview = b.document_preview;
      } else if (b.eventType === "UPDATE_BUBBLE") {
        replaceBubble = { ...newChats[index] };
        replaceBubble.props.data.html = b.html;
        replaceBubble.props.data.text = b.text;
        replaceBubble.props.data.document_preview = b.document_preview;
      }
      const newChatData = newChats.map((c, i) =>
        i === index ? replaceBubble : c
      );
      this.setState({ chatData: newChatData }, () => this.updateScrollbar());
    } else {
    }
  };

  onFAQClick = (faq) => {
  };

  handleSuggestionSelect = (key) => {
    this.setState({
      selectedSuggestion: key,
      showSuggestedQuestions: false,
      suggestionsExpanded: false,
    });
    this.sendMessage(key.split("%%")[1]);
  };

  updateScrollbar = () => {
    if (this.messagesEnd) {
      this.messagesEnd.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.sendMessage(this.state.newMessage);
    }
  };

  informUserDetailsFormSubmission = (userDetails) => {
    this.setState({
      enableUserDetailsForm: false,
    });

    this.getGreetings();
  };

  loadSession = (logId, sessionId) => {
    this.fetchChat(logId, sessionId);
    this.setState({
      enableInitialScreen: false,
      currentSessionId: sessionId,
    });
  };

  render() {
    let {
      chatData,
      suggestedQuestionsData,
      selectedSuggestion,
      newMessage,
      openModal,
      feedbackComment,
      snackbar,
      showForm,
      form,
      showFormSubmissionMessage,
      formSubmissionMessageDetails,
      widgetSettings,
      showSuggestedQuestions,
      showQuiz,
      showDocumentPreview,
      showQuizReport,
      quiz,
      hideClose,
      enableUserDetailsForm,
      enableInitialScreen,
      suggestionsExpanded,
      hideChatInput,
      customButtonData,
      customBotName,
      customBotNameLoaded,
      customBotAvatar,
      answerForDocumentPreview,
    } = this.state;

    return (
      <div className="chat-page-container">
        <Helmet>
          <title>{this.state.tabTitle}</title>
        </Helmet>
        {widgetSettings.showPeepingImg &&
          widgetSettings.peepingImgPosition === "top" && (
            <div className="top-peeping-img-face">
              <img
                src={
                  widgetSettings.peepingImgCustom
                    ? widgetSettings.peepingImgCustom
                    : DefaultPeepingFace
                }
                className="top-peeping-img"
              />
            </div>
          )}
        {widgetSettings.showPeepingImg &&
          widgetSettings.showPeepingImgHands &&
          widgetSettings.peepingImgPosition === "top" && (
            // widgetSettings.peepingImgCustom === "" && (
            <div className="peeping-img-hands">
              <img src={WalterLeftHand} className="peeping-img-left-hand" />
              <img src={WalterRightHand} className="peeping-img-right-hand" />
            </div>
          )}
        {widgetSettings.showPeepingImg &&
          widgetSettings.peepingImgPosition === "side" && (
            <div
              className={
                widgetSettings.widgetPosition === "right"
                  ? "left-side-peeping-img-face"
                  : "right-side-peeping-img-face"
              }
            >
              <img
                src={
                  widgetSettings.peepingImgCustom
                    ? widgetSettings.peepingImgCustom
                    : DefaultPeepingFace
                }
                className="side-peeping-img"
              />
            </div>
          )}
        {widgetSettings.showPeepingImg &&
          widgetSettings.showPeepingImgHands &&
          widgetSettings.peepingImgPosition === "side" && (
            // widgetSettings.peepingImgCustom === "" && (
            <div
              className={
                widgetSettings.widgetPosition === "right"
                  ? "left-side-peeping-img-hand-wrapper"
                  : "right-side-peeping-img-hand-wrapper"
              }
            >
              <img
                src={WalterSideHand}
                className={
                  widgetSettings.widgetPosition === "right"
                    ? "left-side-peeping-img-hand"
                    : "right-side-peeping-img-hand"
                }
              />
            </div>
          )}
        <div
          className={
            widgetSettings.widgetPosition === "right"
              ? "chat-right-position-shape"
              : "chat-left-position-shape"
          }
          style={{
            marginTop:
              widgetSettings.showPeepingImg &&
              widgetSettings.peepingImgPosition === "top"
                ? "55px"
                : "12px",
            marginLeft:
              widgetSettings.showPeepingImg &&
              widgetSettings.peepingImgPosition === "side" &&
              widgetSettings.widgetPosition === "right"
                ? "63px"
                : "12px",
            marginRight:
              widgetSettings.showPeepingImg &&
              widgetSettings.peepingImgPosition === "side" &&
              widgetSettings.widgetPosition === "left"
                ? "63px"
                : "12px",
            height:
              widgetSettings.showPeepingImg &&
              widgetSettings.peepingImgPosition === "top"
                ? "calc(100% - 70px)"
                : "calc(100% - 50px)",
            width:
              widgetSettings.showPeepingImg &&
              widgetSettings.peepingImgPosition === "side"
                ? "calc(100% - 75px)"
                : "calc(100% - 24px)",
          }}
        >
          {enableUserDetailsForm && !enableInitialScreen && (
            <UserDetailsForm
              authToken={this.state.authToken}
              headerMessage={this.state.userDetailsFormMessage}
              formLayout={this.state.userDetailsFormLayout}
              informSubmission={this.informUserDetailsFormSubmission}
              hideClose={hideClose}
              closePopup={this.closePopup}
              headerIcon={widgetSettings.headerIcon}
              isTest={widgetSettings.isTest}
              prefilledFormData={this.state.prefilledFormData}
              hideHeaderAvatar={widgetSettings.showPeepingImg}
              customBotAvatar={customBotAvatar}
            />
          )}
          {!enableUserDetailsForm &&
            enableInitialScreen &&
            !widgetSettings.isTest && (
              <InitialScreen
                authToken={this.state.authToken}
                headerMessage={this.state.userDetailsFormMessage}
                hideClose={hideClose}
                closePopup={this.closePopup}
                headerIcon={widgetSettings.headerIcon}
                sessionData={this.state.sessionData}
                loadSession={this.loadSession}
                startNewConversation={this.startNewConversation}
                homeScreenMessage={widgetSettings.homeScreenMessage}
                hideHeaderAvatar={widgetSettings.showPeepingImg}
                errorBanner={this.state.errorBanner}
                customBotAvatar={customBotAvatar}
                turnOffErrorBanner={() => {
                  this.setState({
                    ...this.state,
                    errorBanner: false,
                  });
                }}
              />
            )}
          {!enableUserDetailsForm && !enableInitialScreen && (
            <div className="chat-wrapper">
              {!widgetSettings.isTest && (
                <div className="chat-messages-header">
                  {/* <div className="flex-container"> */}
                  {!showForm && !showQuiz && (
                    <i
                      className="icon-left-chevron"
                      onClick={() => {
                        this.fetchSession();
                        this.setState({
                          chatData: [],
                          enableInitialScreen: true,
                        });
                      }}
                    />
                  )}
                  <div className="bot-name-wrapper">
                    {customBotNameLoaded
                      ? customBotName
                        ? customBotName
                        : widgetSettings.headerName
                      : ""}
                  </div>
                  <div>
                    {!hideClose && (
                      <button
                        type="button"
                        className="chat-popup-close-btn"
                        onClick={this.closePopup}
                      >
                        <i className="icon-close" title="close"></i>
                      </button>
                    )}
                  </div>
                </div>
              )}
              <div
                className="chat-body-wrapper"
                style={{
                  borderRadius:
                    widgetSettings.widgetPosition === "right"
                      ? "25px 25px 30px 60px"
                      : "25px 25px 60px 30px",
                }}
              >
                {!showForm &&
                  !showQuiz &&
                  !showDocumentPreview &&
                  customButtonData &&
                  customButtonData.length > 0 &&
                  customButtonData.map((button) => (
                    <button
                      className="header-custom-btn"
                      title={button.label}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.onCustomButtonClick(button);
                      }}
                      type="button"
                      disabled={
                        this.state.disableCustomButton ||
                        this.state.isConversationalQuizActive ||
                        this.state.disableInput
                      }
                    >
                      {button.label}
                    </button>
                  ))}

                {this.state.errorBanner &&
                  widgetSettings.isTest &&
                  window.location.hasOwnProperty("ancestorOrigins") &&
                  window.location.ancestorOrigins.length > 0 &&
                  window.location.ancestorOrigins[0].includes("portal") && (
                    <div
                      className={
                        !showForm &&
                        !showQuiz &&
                        !showDocumentPreview &&
                        customButtonData &&
                        customButtonData.length > 0
                          ? "error-banner"
                          : "error-banner error-banner-radius"
                      }
                    >
                      <div className="w-calc">
                        To test out the student portal, please go to{" "}
                        <a
                          href={
                            window.location.origin.includes(".staging")
                              ? "https://chatbot.staging.noodlefactory.ai" +
                                `?agent=${
                                  this.state.agentIdParameter.split("=")[1]
                                }&through=admin-portal`
                              : window.location.origin.includes("chatbotus.") ||
                                window.location.origin.includes("widgetus.")
                              ? "https://chatbotus.noodlefactory.ai" +
                                `?agent=${
                                  this.state.agentIdParameter.split("=")[1]
                                }&through=admin-portal`
                              : "https://chatbot.noodlefactory.ai" +
                                `?agent=${
                                  this.state.agentIdParameter.split("=")[1]
                                }&through=admin-portal`
                          }
                          target="_blank"
                          className={"error-banner-a"}
                        >
                          {window.location.origin.includes(".staging")
                            ? "chatbot.staging.noodlefactory.ai"
                            : window.location.origin.includes("chatbotus.") ||
                              window.location.origin.includes("widgetus.")
                            ? "chatbotus.noodlefactory.ai"
                            : "chatbot.noodlefactory.ai"}
                        </a>{" "}
                      </div>
                      <button
                        type="button"
                        className="error-banner-close-btn"
                        onClick={() => {
                          this.setState({
                            ...this.state,
                            errorBanner: false,
                          });
                        }}
                      >
                        <i className="icon-close" title="close"></i>
                      </button>
                    </div>
                  )}

                {widgetSettings.branding && (
                  <a
                    className="brand-tab"
                    href="https://noodlefactory.ai"
                    target="_blank"
                  >
                    <span>
                      <i className="icon-power" />
                      Noodle Factory
                    </span>
                  </a>
                )}
                <>
                  <div className="chat-messages-container">
                    {chatData &&
                      chatData?.length > 0 &&
                      chatData?.map((component) =>
                        this.addHtmlComponent(component)
                      )}
                    {this.state.isLoading && <BotReply replyType={"loading"} />}
                    <div
                      style={{ width: "100%", height: "32px", clear: "both" }}
                      ref={(el) => {
                        this.messagesEnd = el;
                      }}
                    ></div>
                  </div>

                  <div className="chat-input-elements">
                    {!hideChatInput && suggestedQuestionsData.length > 0 && (
                      <div
                        ref={this.setWrapperRef}
                        className={
                          suggestionsExpanded
                            ? "suggested-questions-container"
                            : "suggested-questions-container suggested-questions-collapsed"
                        }
                      >
                        <div
                          className="suggested-questions-header"
                          onClick={() => {
                            this.setState({
                              suggestionsExpanded: !suggestionsExpanded,
                            });
                            // this.updateScrollbar();
                          }}
                        >
                          {suggestionsExpanded ? (
                            <div className="heading">Suggestions</div>
                          ) : (
                            <div className="heading"></div>
                          )}
                          <div className="view-control-btn">
                            {suggestionsExpanded ? (
                              <i className="icon-down-chevron" />
                            ) : (
                              <i className="icon-up-chevron" />
                            )}
                          </div>
                        </div>
                        <div className="suggested-questions-body">
                          {suggestedQuestionsData.map((item) => (
                            <button
                              key={`${item._id}%%${item.name}`}
                              disabled={this.state.disableInput}
                              className={
                                this.state.disableInput
                                  ? "topic-button-disabled"
                                  : "topic-button"
                              }
                              onClick={() => {
                                this.handleSuggestionSelect(
                                  `${item._id}%%${item.name}`
                                );
                              }}
                            >
                              {item.name}
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                    <div className="chat-footer">
                      <div ref={this.setInputRef}>
                        <ChatInput
                          value={newMessage}
                          avatarIcon={UserAvatarIcon}
                          onKeyPress={this.handleKeyPress}
                          onSend={() => this.sendMessage(newMessage)}
                          placeholder="Type here to ask something..."
                          onChange={(value) =>
                            this.onFieldChange(value, "newMessage")
                          }
                          isDisabled={
                            this.state.hideChatInput || this.state.disableInput
                          }
                          widgetPosition={widgetSettings.widgetPosition}
                        />
                      </div>
                    </div>
                  </div>
                </>

                {showForm && (
                  <div
                    className={
                      widgetSettings.isTest
                        ? "chat-overlay-pulled-container"
                        : "chat-overlay-container"
                    }
                  >
                    {showFormSubmissionMessage && (
                      <FormSubmissionMessage
                        message={formSubmissionMessageDetails.message}
                        heading={formSubmissionMessageDetails.heading}
                        buttonLabel={formSubmissionMessageDetails.buttonLabel}
                        buttonAction={this.handleSubmissionMessageAction}
                        type={formSubmissionMessageDetails.type}
                      />
                    )}
                    {!showFormSubmissionMessage && (
                      <Form
                        onBack={this.handleFormBack}
                        onSubmit={this.handleFormSubmit}
                        formSchema={form.layout.formSchema}
                        uiSchema={form.layout.uiSchema}
                        submitButtonLabel={
                          form.layout.submitLabelText
                            ? form.layout.submitLabelText
                            : "SUBMIT"
                        }
                        primaryHeaderText={form.layout.headerText}
                        formId={form._id}
                      />
                    )}
                  </div>
                )}
                {showQuiz && (
                  <div
                    className={
                      widgetSettings.isTest
                        ? "chat-overlay-pulled-container"
                        : "chat-overlay-container"
                    }
                  >
                    {!showQuizReport.open && (
                      <QuizGenerator
                        quizData={quiz}
                        onBack={() => {
                          this.setState({ showQuiz: false, chatData: [] });
                          this.fetchChat("", this.state.currentSessionId);
                        }}
                        onQuit={this.handleQuizQuit}
                        onSubmit={this.handleQuizSubmit}
                        disableSubmit={this.disableQuizButton}
                      />
                    )}
                    {showQuizReport.open && (
                      <QuizReport
                        // hideScores={hideScores}
                        submissionData={showQuizReport.data}
                        type={showQuizReport.type}
                        onClick={this.handleQuizReportAction}
                        displayedQuestionsId={this.state.quizDisplayedQuestions}
                      />
                    )}
                  </div>
                )}
                {showDocumentPreview && (
                  <div
                    className={
                      widgetSettings.isTest
                        ? "chat-overlay-pulled-container"
                        : "chat-overlay-container"
                    }
                  >
                    <div class="document-preview-wrapper">
                      <DocumentPreview
                        onClickBackButton={() => {
                          this.setState({
                            showDocumentPreview: false,
                          });
                        }}
                        answer={this.state.answerForDocumentPreview}
                        keyword={this.state.answerForDocumentPreview}
                        onSubmit={this.generateContent}
                        content={this.state.documentPreviewContent}
                      />
                    </div>
                  </div>
                )}
                <Modal
                  openModal={openModal}
                  onRequestClose={() => {
                    this.postFeedback(0, this.state.feedbackId);
                    this.setState({
                      feedbackComment: "",
                      feedbackId: "",
                      openModal: false,
                    });
                  }}
                  buttonLabel="Send Feedback"
                  buttonAction={this.sendFeedbackComment}
                >
                  <div className="modal-heading">
                    Tell us what we can do better.
                  </div>
                  <TextInput
                    inputType="text"
                    name="feedbackComment"
                    placeholder="Type your feedback here..."
                    value={feedbackComment}
                    inputChange={(event, name) =>
                      this.onFieldChange(event.target.value, name)
                    }
                  />
                </Modal>
                <Snackbar
                  isOpen={snackbar.isOpen}
                  primaryMessage={snackbar.message}
                  type={snackbar.type}
                  onRequestClose={() =>
                    this.setState({ snackbar: { ...snackbar, isOpen: false } })
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default NewChat;
